import { Div } from "../../../base/components/native/div"
import { CENTER } from "../../../base/helpers/style"
import services from "../../../services"
import { Frame } from "../../slider/frame"
import { AppointmentItemDaily } from "./appointment-item-daily"
import { DayTitle } from "./day-title"

export const Day = (date: Date) => {

    const base = Div()
    base.cssClass({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        overflowY: 'auto',
        padding: '60px 0 120px 0',
        direction: 'rtl',
    })
    const title = DayTitle(date)
    base.append(title)

    const list = Div()
    services.appointments.byDate(date).then(appointments => {
        appointments.sort((a: any, b: any) => a.time.from > b.time.from ? 1 : -1)
        appointments.forEach(appointment => {
            const item = AppointmentItemDaily(appointment)
            list.append(item)
        })
    })
    base.append(list)
    list.cssClass({
        marginTop: '40px',
        marginRight: '60px',
        borderRight: '3px dotted #ddd',
    })

    // دشت امروز
    // const list = Div()
    // list.cssClass({
    //     display: 'flex',
    //     flexDirection: 'column',
    //     width: '100%',
    //     height: '100%',
    //     overflow: 'hidden',
    //     overflowY: 'auto',
    //     padding: '60px 0 120px 0'
    // })
    // base.append(list)

    // for (let i = 0; i < 24; i++) {
    //     const div = Div(i + '')
    //     div.cssClass({
    //         width: '100%',
    //         height: '40px',
    //         paddingTop: '10px',
    //         color: '#bbb',
    //         paddingRight: '22px',
    //         borderBottom: '1px solid #eee',
    //         flexShrink: '0',
    //     })
    //     list.append(div)
    // }
    // list.el.scrollTop = 480

    return base
}