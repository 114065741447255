import { ghostify } from "../../../base/components/advanced/ghost/ghost"
import { Div } from "../../../base/components/native/div"
import { CENTER } from "../../../base/helpers/style"
import helpers from "../../../helpers"
import { emitter } from "../../../services/emitter"

export const Cell = (content: string) => {

    const base = Div(content)
    base.cssClass({
        position: 'relative',
        ...CENTER,
        userSelect: 'none',
        height: '50px',
    })
    return base
}