import { Div } from "../../base/components/native/div"
import { CENTER } from "../../base/helpers/style"

export const Frame = (content?: string) => {

    const base = Div(content)
    base.cssClass({
        ...CENTER,
        width: '100%',
        height: base.el.parentElement?.clientHeight + 'px',
        position: 'relative',
    })

    base.on('mounted', () => {
        const P = base.el.parentElement?.getBoundingClientRect()
        base.style({ height: P?.height + 'px' })
    })


    return base
}