import { Base } from "../../../base/components/base"
import { Div } from "../../../base/components/native/div"
import state from "../../../base/services/state"
import { ActionBack } from "../../shared/action-back"
import { ActionButton } from "../../shared/action-button"
import { DayPicker } from "../../shared/calendar/day-picker/day-picker"
import { TimeRange } from "../../shared/calendar/time-picker/time-range"

export const EditTime = (label: string, value: string) => {

    const base = Base()
    const name = Div(label)
    const time = TimeRange()
    const next = ActionButton('تایید')
    const prev = ActionBack('لغو')
    const keys = Div()
    keys.append(next, prev)
    base.append(name, time, keys)

    time.setValue(value)
    next.on('click', () => base.emit('update'))
    prev.on('click', () => base.emit('cancel'))

    keys.cssClass({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '40px',
        gap: '16px',
    })
    name.cssClass({
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '5px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    return Object.assign(base, {
        getValue() {
            return time.getValue()
        }
    })
}