import { Div } from "../../base/components/native/div"
import router, { IRouteParams } from "../../base/lib/router"
import state from "../../base/services/state"
import { waitFor } from "../../base/utils/wait"
import helpers from "../../helpers"
import services from "../../services"
import { Body } from "../shared/body"
import { AddIncomeWizard } from "./add-income-wizard"

export const AddIncomePage = () => {

    let _state: any = {}
    const base = Div()
    const body = Body()
    const wizard = AddIncomeWizard()
    body.append(wizard)
    base.append(body)

    body.cssClass({ display: 'flex', flexDirection: 'column', alignItems: 'center' })
    base.cssClass(helpers.styles.PAGE_BASE)

    wizard.on('done', async () => {
        // const id = await services.incomes.add(state.get('new-income'))
        // router.goto(`/clients/${id}`, { replace: true })

        if (_state.from?.includes('incomes')) {
            router.goto('/clients/' + _state.clientId)
        } else {
            router.back()
        }
    })

    function exit({ to }: IRouteParams) {
        return base.style(helpers.styles.PAGE_EXIT)
    }

    async function enter({ query, from, params, data }: IRouteParams) {
        wizard.reset()
        _state.from = from
        _state.clientId = params.clientId
        // console.log('from ->', from);

        if (from === '/') base.style(helpers.styles.PAGE_EXIT)
        await waitFor(helpers.styles.PAGE_TRANSITION_DURATION)
        base.style(helpers.styles.PAGE_ENTER)
    }

    return Object.assign(base, { enter, exit })
}