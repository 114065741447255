import { Wizard } from '../../shared/slide/wizard'
import { SetReminderIntroSlide } from './setup-reminder-slides/intro'
import { SetReminderTitleSlide } from './setup-reminder-slides/set-business-title'
import { SetReminderMessageSlide } from './setup-reminder-slides/set-message'

export const SetReminderWizard = () => {

    const intro = SetReminderIntroSlide()
    const title = SetReminderTitleSlide()
    const message = SetReminderMessageSlide()
    // const desc = DescSlide()
    // const fees = FeesSlide()
    // const paid = PaidSlide()
    const base = Wizard([intro, title, message])

    return base
}