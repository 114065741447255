import { Div } from "../../../../base/components/native/div"
import { HHPicker } from "./hh-picker"
import { MMPicker } from "./mm-picker"

export const HHMM = () => {
    // Todo separate time range hours and minutes
    const base = Div()
    const hh = HHPicker()
    const dots = Div(':')
    const mm = MMPicker()
    base.append(mm, dots, hh)
    base.cssClass({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        // width: '100%',
        maxWidth: '320px',
        margin: '0 auto',
    })

    return Object.assign(base, {
        getValue: () => {
            const h = hh.getValue()
            const m = mm.getValue()
            return `${h}:${m}`
        },
        setValue: (value: any) => {
            if (!value) return
            const [h, m] = value.split(':')
            hh.setValue(h)
            mm.setValue(m)
        }
    })

}