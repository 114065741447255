import { Base } from '../../../base/components/base'
import { emitter } from '../../../services/emitter'
import { ITab } from './tab'
import { TabsContainer } from './tabs-container'
import { TabsNav } from './tabs-nav'

export const Tabs = (items: ITab[], options = {}) => {

    const base = Base()
    const container = TabsContainer(items)
    const nav = TabsNav(items)
    base.append(container, nav)

    nav.on('item-selected', id => {
        container.select(id)
        emitter.emit('tab-clicked', id)
    })
    container.on('tab-selected', id => {
        emitter.emit('tab-selected', id)
        nav.select(id)
    })

    const selected = items.find(item => item.selected)
    if (selected) {
        container.select(selected.id)
        nav.select(selected.id)
    }

    function reset() {
        nav.reset()
        container.reset()
    }

    function showNav() {
        nav.show()
    }
    function hideNav() {
        nav.hide()
    }

    // function unlock() {
    //     container.unlock()
    // }
    // function setData(client: any) {
    //     container.setData(client)
    // }

    return Object.assign(base, { reset, showNav, hideNav })
}

export interface ITabOptions {
}