import app from './app'
import { disableContextMenu, disableTouchStartPassive } from './base/utils/event-handlers'
import db from './services/db'
import flags from './services/flags'

(async () => {
    await db.init()
    await flags.init()
    disableContextMenu({ touch: true, mouse: false })
    disableTouchStartPassive()
    // TestService()
    document.body.appendChild(app.el)
    window.onerror = (message, source, lineno, colno, error) => {
        alert(message)
    }
})()
