import { Div } from '../../../base/components/native/div'
import ldb from '../../../base/lib/ldb'
import state from '../../../base/services/state'
import images from '../../../configs/images'
import helpers from '../../../helpers'
import { ActionBack } from '../../shared/action-back'
import { ActionButton } from '../../shared/action-button'
import { DInput } from '../../shared/d-input'
import { PageHeader } from '../../shared/page-header'
import { WizardSlide } from '../../shared/slide/slide'

export const SetReminderSendSlide = () => {

    const base = WizardSlide()
    const header = PageHeader('ارسال پیام')
    const message = Div()
    message.cssClass({
        backgroundColor: '#ea4c8922',
        padding: '30px',
        borderRadius: '30px',
        // border: '1px solid #ea4c89',
        borderBottomRightRadius: '0',
        marginBottom: '10px',
        marginTop: '10px',
    })

    const copy = ActionButton('کپی پیغام', { icon: images.ICONS.COPY, background: '#eee', color: '#222' })
    const send = ActionButton('ارسال از طریق تلگرام', { icon: images.ICONS.TELEGRAM, background: '#eee', color: '#222' })
    const prev = ActionBack(helpers.strings.PREV_STEP)
    const keys = Div()
    keys.append(header, message, copy, send, prev)
    base.append(keys)

    send.on('click', () => {
        const text = state.get('new-reminder')
        if (navigator.share) {
            navigator.share({
                title: 'Share via Telegram',
                text: text.description, // The text to be shared
                url: ''     // You can leave this empty if you just want to share the text
            }).then(() => {
                console.log('Sharing successful');
            }).catch((error) => {
                console.error('Sharing failed:', error);
            });
        } else {
            alert('Web Share API is not supported in your browser.');
        }
        base.emit('done')
    })
    copy.on('click', () => {
        console.log('state.get(new-reminder)', state.get('new-reminder'));

        navigator.clipboard.writeText(state.get('new-reminder').description).then(() => {
            copy.setText('کپی شد')
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
        setTimeout(() => {

            base.emit('done')
        }, 400);
    })
    prev.on('click', () => base.emit('prev'))
    base.on('enter', () => {
        const body = state.get('new-reminder')
        message.html(body.description.replace(/\n/g, '<br>'))
    })

    keys.cssClass({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '40px',
        gap: '16px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    return base
}