import { Wizard } from '../../shared/slide/wizard'
import { DescSlide } from './slides/desc';
import { FeesSlide } from './slides/fees';
import { TimeSlide } from './slides/time';
import { UserSlide } from './slides/user';


export const AddAppointmentFromDayWizard = () => {
    console.log('AddAppointmentFromDayWizard');

    const user = UserSlide()
    const time = TimeSlide()
    const desc = DescSlide()
    const fees = FeesSlide()
    const base = Wizard([user, time, desc, fees])

    return base
}