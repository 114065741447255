import { Div } from '../../../../base/components/native/div'
import state from '../../../../base/services/state'
import helpers from '../../../../helpers'
import { ActionBack } from '../../../shared/action-back'
import { ActionButton } from '../../../shared/action-button'
import { DInput } from '../../../shared/d-input'
import { WizardSlide } from '../../../shared/slide/slide'

export const SetReminderIntroSlide = () => {

    const base = WizardSlide()
    base.cssClass({
        padding: '15px 60px',
    })

    const intro = Div(`
        <b>صاحب کسب و کار عزیز!</b><br> 
        قبل از ایجاد یادآور لازم است مشتری درباره شما بداند و اجازه دهد پیغام‌های شما به او برسد.<br> 
         برای این کار لطفا اطلاعاتی  که در صفحات بعدی پرسیده می‌شود را با دقت وارد کنید.
         `
    )
    intro.cssClass({
        textAlign: 'center',
        margin: '40px 20px',
    })
    const desc = DInput('شرح کار', 'توضیح کارهای نوبت', { size: 20, type: 'textarea' })
    const next = ActionButton(helpers.strings.NEXT_STEP)
    const keys = Div()
    keys.append(next)
    keys.cssClass({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '40px',
        gap: '16px',
    })
    base.append(intro, keys)

    next.on('click', () => { base.emit('next') })
    base.on('reset', () => desc.clear())



    return base
}