import { Base } from "../../../../../../base/components/base"
import { Div } from "../../../../../../base/components/native/div"
import { H4, H3 } from "../../../../../../base/components/native/h"
import { jDateFormatter } from "../../../../../../base/helpers/date"
import { NUMBERS_DELIMITER_REGEX } from "../../../../../../base/helpers/regex"
import { ABSOLUTE } from "../../../../../../base/helpers/style"
import locale from "../../../../../../helpers/locale"
import services from "../../../../../../services"


export const TransactionsList = () => {

    const base = Base()
    base.style({
        padding: '0px 60px 60px 0px',
        position: 'relative',
    })


    return base
}