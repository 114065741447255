import { ghostify } from "../../../base/components/advanced/ghost/ghost"
import { Div } from "../../../base/components/native/div"
import { Img } from "../../../base/components/native/img"
import { NUMBERS_DELIMITER_REGEX } from "../../../base/helpers/regex"
import { EASE } from "../../../base/helpers/style"
import router from "../../../base/lib/router"
import images from "../../../configs/images"
import helpers from "../../../helpers"
import locale from "../../../helpers/locale"
import { TAppointment } from "../../../interfaces/appointments"
import { TClient } from "../../../interfaces/client"
import services from "../../../services"
import { Price } from "../price"

export const AppointmentItemDaily = (item: TAppointment) => {

    const base = Div()
    base.cssClass({
        margin: '0px -3px 40px 20px',
        borderRight: `3px solid #ea4c89`,
        padding: '0 15px 0px',
        cursor: 'pointer',
        textAlign: 'right',
        position: 'relative',
    })
    ghostify(base, { bg: '#fff' })
    base.el.onclick = () => router.goto(`/clients/${item.clientId}/appointments/${item.id}`)

    const circleStart = Div()
    circleStart.cssClass({
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: '#fff',
        border: '3px solid #ea4c89',
        position: 'absolute',
        top: '0px',
        right: '-6px',
    })
    base.append(circleStart)

    const timeStart = Div(locale.replaceLatinDigits(item.time?.from))
    timeStart.cssClass({
        position: 'absolute',
        top: '-5px',
        right: '-48px',
        fontSize: '14px',
        color: '#666',
        opacity: '0.8'
    })
    base.append(timeStart)

    const circleEnd = Div()
    circleEnd.cssClass({
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: '#fff',
        border: '3px solid #ea4c89',
        position: 'absolute',
        bottom: '0px',
        right: '-6px',
    })
    base.append(circleEnd)

    const timeEnd = Div(locale.replaceLatinDigits(item.time?.to))
    timeEnd.cssClass({
        position: 'absolute',
        bottom: '-5px',
        right: '-48px',
        fontSize: '14px',
        color: '#666',
        opacity: '0.8'
    })
    base.append(timeEnd)

    const client = Div()
    services.clients.byId<TClient>(item.clientId).then((c) => {
        client.text(c?.name)
    })
    client.cssClass({
        fontSize: '18px',
        fontWeight: 'bold',
        position: 'relative',
        top: '-10px',
    })
    base.append(client)

    const description = Div(item.description)
    description.cssClass({
        fontSize: '16px',
        margin: '5px 0',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical'
    })
    base.append(client, description)

    const fees = Price(item.fees)
    fees.cssClass({
        fontSize: '14px',
        color: '#ea4c89',
        marginTop: '5px'
    })
    if (item.fees) base.append(fees)

    const arrow = Img(images.ICONS.ARROW_LEFT, { width: 22 })
    arrow.cssClass({
        margin: '10px 0',
    })
    base.append(arrow)

    return base
}