import { Wizard } from '../../shared/slide/wizard'
import { BotSlide } from './login-slides/bot-slide'
import { TelegramSlide } from './login-slides/telegram-slide'
import { VerifySlide } from './login-slides/verify-slide'

export const LoginWizard = () => {

    const telegramSlide = TelegramSlide()
    const botSlide = BotSlide()
    const verify = VerifySlide()
    const base = Wizard([telegramSlide, botSlide, verify])

    return base
}