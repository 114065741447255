import { shortUUID } from "../base/utils/id-generator"
import { TAppointment } from "../interfaces/appointments"
import { ReminderStatus } from "../interfaces/reminder"
import db from "./db"
import flags from "./flags"

export default {
    async getAll(page: number) {
        return await db.all('appointments', page)
    },
    async byClient(clientId: string) {
        return (await db.find('appointments', { index: 'clientId', value: clientId })).filter((a: any) => !a.isDeleted)
    },
    async byId<T>(id: string): Promise<T> {
        return db.byId('appointments', id) as T
    },
    async add(data: any) {
        await new Promise(r => setTimeout(r, 500))
        const appointment = {
            ...data,
            id: shortUUID(),
            at: new Date(),
            synced: false
        }
        const saved = await db.save('appointments', appointment)
        flags.set('CALENDAR_NOT_EMPTY', true)
        return saved
    },
    update(id: string, data: any) {
        return db.update('appointments', id, data)
    },
    byDate(date: Date) {
        return db.find('appointments', { index: 'date', value: date })
    },
    // byDateRange(from: Date, to: Date) {
    //     return db.find('appointments', { index: 'date', from, to })
    // },
    async delete(id: string) {
        const appointment = await db.byId('appointments', id) as TAppointment
        if (!appointment) return
        appointment.isDeleted = true
        return db.update('appointments', id, appointment)
    }
}