import app from './app'
import ldb from './base/lib/ldb'
import { XHR } from './base/lib/xhr'
import { disableContextMenu, disableTouchStartPassive } from './base/utils/event-handlers'
import { uuidv4 } from './base/utils/id-generator'
import db from './services/db'
import flags from './services/flags'

(async () => {
    window.onerror = (message, source, lineno, colno, error) => {
        alert(message)
    }
    await db.init()
    flags.init()
    disableContextMenu({ touch: true, mouse: false })
    disableTouchStartPassive()
    document.body.appendChild(app.el)
    // window.ononline = () => app.emit('online')
    if (!ldb.get('app-id')) {
        ldb.set('app-id', uuidv4())
    }
    function logUserEvent(eventType: string) {
        XHR.post('/api/track/events', {
            event: eventType,
            timestamp: new Date().toISOString(),
            appId: ldb.get('app-id'),
        }).then((response) => {
            console.log('Event logged:', response)
        }).catch((error) => {

            console.error('Error logging event:')
            console.log(error);
        })
    }

    // Track when the app is opened
    // window.addEventListener('DOMContentLoaded', () => {
    logUserEvent('open')
    // })

    // Track when the app is closed
    window.addEventListener('beforeunload', () => {
        console.log('beforeunload');

        logUserEvent('close')
    })

})()
