import { Div } from '../../../base/components/native/div'
import { Img } from '../../../base/components/native/img'
import { CENTER } from '../../../base/helpers/style'
import ldb from '../../../base/lib/ldb'
import state from '../../../base/services/state'
import images from '../../../configs/images'
import helpers from '../../../helpers'
import services from '../../../services'
import { ActionBack } from '../../shared/action-back'
import { ActionButton } from '../../shared/action-button'
import { DInput } from '../../shared/d-input'
import { WizardSlide } from '../../shared/slide/slide'

export const SetReminderIntroSlide = () => {

    const base = WizardSlide()
    base.cssClass({
        padding: '15px 60px',
        ...CENTER,
        flexDirection: 'column',
    })

    const icon = Img(images.ICONS.BULB, { width: 66 })
    base.append(icon)

    const intro = Div(`
    مشتریان شما می‌توانند از طریق تلگرام، از جلسات و نوبت‌های آینده خود مطلع شوند.
    <br/>
    برای این کار، کافی است لینک بات تلگرام فلپ را برایشان ارسال کنید تا عضو شوند.
    <br/>
    <br/>
    لطفاً اطلاعات خواسته‌شده در صفحات بعدی را وارد کنید تا متن پیام و لینک بات به‌صورت خودکار برای ارسال آماده شود.
         `
    )
    intro.cssClass({
        textAlign: 'center',
        margin: '20px 0px 10px',
        fontSize: '18px',
        maxWidth: '255px'
    })
    const next = ActionButton(helpers.strings.NEXT_STEP)
    const keys = Div()
    keys.append(next)
    keys.cssClass({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '40px',
        gap: '16px',
    })
    base.append(intro, keys)

    next.on('click', async () => {
        // if (ldb.get('my-business-title')) {
        //     // it will remove from then message etc... BUG
        //     base.emit('skip')
        //     return
        // }
        next.setLoading(true)
        try {


            console.log('state.get(new-reminder)', state.get('new-reminder'));
            const { client } = state.get('new-reminder')
            const phone = client?.contact?.cell?.replace('0', '98')
            const { data: { joinToken } } = await services.reminder.createSubscriptionToken(phone)
            state.merge('new-reminder', { joinToken })
            console.log('joinToken', joinToken);
            next.setLoading(false)
            base.emit('next')
        } catch (error) {
            console.error('error', error);
            next.setLoading(false)
            return
            // Todo: handle better

        }
    })

    return base
}