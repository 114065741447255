import { Base } from "../../base/components/base"
import { Div } from "../../base/components/native/div"
import { IRouteParams } from "../../base/lib/router"
import { waitFor } from "../../base/utils/wait"
import helpers from "../../helpers"
import { TAppointment } from "../../interfaces/appointments"
import services from "../../services"
import { Body } from "../shared/body"
import { PageHeader } from "../shared/page-header"
import { Prop } from "../shared/prop"


export const NewNotificationPage = () => {
    const base = Base()

    const header = PageHeader('ارسال یادآور به مشتری')
    const body = Body()
    const date = Prop('تاریخ', 'date', 'date')
    const time = Prop('ساعت', 'time', 'time')
    const fees = Prop('دستمزد', 'price', 'fees')
    const desc = Prop('شرح کار', 'text', 'description')
    body.append(date, time, fees, desc)
    base.append(header, body, /* email, notes, address, birthday */)

    // date.on('edit', () => {
    //     date.edit()
    // })

    function setData(appointment: TAppointment) {
        date.setData(helpers.date.format(appointment.date))
        time.setData(appointment.time)
        fees.setData(appointment.fees)
        desc.setData(appointment.description)
        // email.el.innerText = client.email
        // notes.el.innerText = client.notes
        // address.el.innerText = client.address
        // birthday.el.innerText = client.birthday
    }

    base.cssClass(helpers.styles.PAGE_BASE)
    body.cssClass({
        marginTop: '45px',
        padding: '0 0 16px 16px',

    })
    function exit({ to }: IRouteParams) {
        if (to?.includes('add') || to?.includes('edit')) {
            return base.style(helpers.styles.PAGE_EXIT_UP)
        }
        base.style(helpers.styles.PAGE_EXIT)
    }

    async function enter({ params }: IRouteParams) {
        try {
            const _id = params.appointmentId
            const appointment = await services.appointments.byId<any>(_id)
            setData(appointment)
        } catch (error) {
            base.append(Div('خطا در بارگذاری اطلاعات' + error.message))
        } finally {
            await waitFor(helpers.styles.PAGE_TRANSITION_DURATION)
            base.style(helpers.styles.PAGE_ENTER)

        }
    }

    return Object.assign(base, { enter, exit })
}