import router, { IRouteParams } from '../../../base/lib/router'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import services from '../../../services'
import state from '../../../base/services/state'
import { Page } from '../../shared/page'
import { AddAppointmentFromDayWizard } from '../../shared/add-appointment-wizard/add-appoitnment-from-day-wizard'

export const AddAppointmentFromDayPage = () => {

    let _state: any = {}
    const base = Page()
    console.log('AddAppointmentFromDayPage');

    const wizard = AddAppointmentFromDayWizard()
    base.append(wizard)

    wizard.on('done', async () => {
        const appointment = state.get('new-appointment')
        const results = await services.appointments.add(appointment)
        router.back()
        // if (_state.from === '/') return router.back()
        // return router.goto(`/`, { replace: true })
    })

    function exit({ to }: IRouteParams) {
        return base.style(helpers.styles.PAGE_EXIT)
    }

    async function enter({ params, from, to, data }: IRouteParams) {
        wizard.reset()
        if (!params.date) return router.back()
        state.set('new-appointment', { date: new Date(params.date) })
        _state.from = from
        if (from === '/') base.style(helpers.styles.PAGE_EXIT)
        await waitFor(helpers.styles.PAGE_TRANSITION_DURATION)
        base.style(helpers.styles.PAGE_ENTER)
    }

    return Object.assign(base, { enter, exit })
}