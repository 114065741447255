import { ghostify } from "../../../../../../base/components/advanced/ghost/ghost"
import { Base } from "../../../../../../base/components/base"
import { Div } from "../../../../../../base/components/native/div"
import { Img } from "../../../../../../base/components/native/img"
import router from "../../../../../../base/lib/router"
import images from "../../../../../../configs/images"
import { TAppointment } from "../../../../../../interfaces/appointments"

export const AppointmentRepeat = (item: TAppointment) => {

    const base = Base()
    const icon = Img(images.ICONS.REPEAT, { width: 26 })
    const title = Div('تکرار این نوبت')
    base.append(icon, title)

    base.el.onclick = (e) => {
        console.log(`/appointments/${item.id}/notifications/add`);
        
        e.stopPropagation()
        // router.goto(`/clients/${item.clientId}/appointments/${item.id}/notify`)
        // router.goto(`/appointments/${item.id}/repeat`)
        base.emit('repeat')
    }

    ghostify(base, {bg: '#d8f7ee', opacity: 0.2})
    base.cssClass({
        position: 'relative',
        fontSize: '14px',
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center',
        // border: '2px solid #d8f7ee',
        // justifyContent: 'space-between',
        // backgroundColor: '#fff',
        borderRadius: '11px',
        margin: '10px -8px -8px',
        padding: '10px 5px'
    })

    return base

}