import { TClient } from "../../../../interfaces/client"
import { PageHeader } from "../../../shared/page-header"

export const ClientHeader = () => {
    const base = PageHeader()
    base.cssClass({
        textAlign: 'right',
    })
    return Object.assign(base, {
        setClient(client: TClient) {
            base.text(client.name)
        }
    })

}