import { ghostify } from "../../base/components/advanced/ghost/ghost";
import { Button } from '../../base/components/native/button';
import { Div } from "../../base/components/native/div";
import { Img } from "../../base/components/native/img";
import { ABSOLUTE, EASE, HIDE, SHOW } from "../../base/helpers/style";
import router from "../../base/lib/router";
import { ActionButton, IActionButton } from "./action-button";
import { Loading } from "./loading";


export const DangerButton = (text: string, options: IActionButton = {}) => {

    const base = ActionButton(text, options)
    const confirm = Div('تایید')
    const cancel = Div('انصراف')
    confirm.style(HIDE)
    cancel.style(HIDE)
    base.el.setAttribute('role', 'button')
    base.append(confirm, cancel)


    base.el.onclick = (e) => {
        confirm.style(SHOW)
        cancel.style(SHOW)
        setTimeout(() => {
            confirm.style(HIDE)
            cancel.style(HIDE)
        }, 2000);
    }

    confirm.el.onclick = () => {
        console.log('confirm');
        
        base.emit('click')
    }
    cancel.el.onclick = (e) => {
        e.stopPropagation()
        confirm.style(HIDE)
        cancel.style(HIDE)
    }

    confirm.cssClass({
        ...ABSOLUTE,
        right: '164px',
        top: '7px',
        width: 'fit-content',
        zIndex: '9',
    })
    cancel.cssClass({
        ...ABSOLUTE,
        right: '235px',
        top: '7px',
        width: 'fit-content',

    })

    base.cssClass({
        backgroundColor: 'rgb(255 255 255)',
        color: '#ea4c89',
        border: '1px solid #ea4c89',
        overflow: 'visible',
        
    })

    return Object.assign(base, {
        setLoading(v: boolean) {
            base.el.style.opacity = v ? '0.7' : '1'
            base.el.style.pointerEvents = v ? 'none' : 'auto'
            base.append()
        },
        setText(t: string) {
        },
        enable() {
            base.el.removeAttribute('disabled')
        },
        disable() {
            base.el.setAttribute('disabled', 'true')
        },
        // rotate() {
        //     base.style({ transform: 'rotateX(180deg)', pointerEvents: 'none', backfaceVisibility: 'hidden' })
        // },
        // unrotate() {
        //     base.style({ transform: 'rotateX(0deg)', pointerEvents: 'inherit' })
        // }
    })
};
