import { IBaseComponent } from "../../../../base/components/base"
import { Div } from "../../../../base/components/native/div"
import images from "../../../../configs/images"
import { Window } from "../../../slider/window"
import { Days } from "../../../shared/days/days"
import { Tab } from "../../../shared/tabs/tab"

export type TSwipe = {
    direction: number
    frame: IBaseComponent<'div'>
    index: number
} // Todo: handle on swipe event

export const CalendarTab = () => {

    const base = Tab()
    const days = Days()
    base.append(days)

    function refresh(date: Date = new Date()) {
        days.goto(date)
    }

    return Object.assign(base, {
        onFocus() {
            console.log('Clients Tab entered')
        },
        onBlur() {
            console.log('Clients Tab exited')
        },
        refresh,
        label: 'تقویم',
        icon: images.ICONS.CALENDAR,
        id: 'calendar'
    })
}