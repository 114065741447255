import app from '../../../app'
import { Div } from '../../../base/components/native/div'
import ldb from '../../../base/lib/ldb'
import state from '../../../base/services/state'
import helpers from '../../../helpers'
import { ActionBack } from '../../shared/action-back'
import { ActionButton } from '../../shared/action-button'
import { DInput } from '../../shared/d-input'
import { WizardSlide } from '../../shared/slide/slide'

export const SetReminderMessageSlide = () => {

    const base = WizardSlide()
    const desc = DInput('متن پیام ارسالی به مشتری', 'متنی که دوست دارید مشتریان در پیام‌ دریافتی ببینند', { size: 16, type: 'textarea' })
    desc.cssClass({ height: '250px' })
    const next = ActionButton(helpers.strings.NEXT_STEP)
    const prev = ActionBack(helpers.strings.PREV_STEP)
    const keys = Div()
    keys.append(next, prev)
    base.append(desc, keys)

    next.on('click', () => { state.merge('new-appointment', { description: desc.getValue() }); base.emit('next') })
    prev.on('click', () => base.emit('prev'))
    desc.on('input', (value: any) => value.length > 3 ? next.enable() : next.disable())
    base.on('enter', () => {
        console.log('state', state.get('new-reminder'));
        const title = ldb.get('my-business-title')
        const { client, joinToken } = state.get('new-reminder')
        // const appointment = state.get('new-reminder')?.appointment
        const greeting = client?.name + ' ' + 'عزیز،'
        // const date = helpers.date.format(appointment?.date)
        // const time = helpers.locale.replaceLatinDigits('از ساعت ' + appointment?.time?.from + ' تا ' + appointment?.time?.to)

        // Check if already joined otherwise show the link
        const more = 'برای اطلاع‌رسانی سریع و به‌موقع درباره نوبت‌های خود و دریافت مطالب سودمند و به‌روز، لطفاً با کلیک روی لینک زیر به بات تلگرامی ما بپیوندید:'
        const link = 'https://t.me/flepuserbot?start=' + joinToken
        const thanks = 'با سپاس فراوان'
        const text = `${greeting}\n\n ${more}\n\n${link}\n\n${thanks}\n${title}`
        // business.html(state.get('new-reminder')?.title)


        // Optionally, ensure the element is focused
        setTimeout(() => {

            desc.setValue(text)
            if (desc.getValue().length < 4) next.disable()
            state.merge('new-reminder', { description: text })
            desc.focus()

        }, 150)
    })
    base.on('reset', () => desc.clear())

    keys.cssClass({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '40px',
        gap: '16px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    return base
}