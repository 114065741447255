import { Div } from "../../../base/components/native/div";
import { Img } from "../../../base/components/native/img";
import images from "../../../configs/images";
import { ActionButton } from "../../shared/action-button";

export const About = () => {
    const base = Div()
    const title = Div('فـلِـپـــ')
    const version = Div('نسخه ۱.۰.۳')
    const description = Div(`
    فلپ همراه کسب و کار شماست!
    <br/>
    <br/>
    به کمکش می‌توانید:
<br/>
<br/>
<div style="font-size: 18px; font-weight: bold; width:170px; margin:0 auto; line-height:22px; color:#ea4c89">
مشتریان خود را ثبت و مدیریت کنید    <br/><br/>
برای قرارها یادآور خودکار بفرستید<br/><br/>
به مشتریان به سادگی نوبت دهید<br/><br/>
جلسات کاری را ثبت و پیگیری کنید<br/><br/>
ارتباط با مشتریان را مدیریت کنید<br/><br/>
درآمدها را محاسبه و ثبت نمایید<br/><br/>
هزینه‌ها را پیگیری و مدیریت کنید<br/><br/>
گزارش‌های دخل و خرج را مشاهده کنید<br/><br/>
با آنها چت پشتیبانی داشته باشید

</div>

<h2>فلپ برای چه کسب و کارهایی مناسب است؟</h2>
پزشکان،
مشاوران،
تراپیست‌ها،
وکلا،
معلمان،
آرایشگران،
مربیان خصوصی،
ماساژورها،
ناخن‌کاران،
برگزارکنندگان رویدادها،
مراکز آموزشی،
...






<br/>
<br/>
فلپ سعی می‌کند  همه آنچه برای مدیریت کارآمد کسب و کار کوچک و متوسط  نیاز دارید را در یک اپلیکیشن گرد هم آورد.






    `)
    base.append(title, version, description)

    title.cssClass({
        fontSize: '36px',
        fontWeight: 'bold',
    })

    version.cssClass({
        fontSize: '16px',
        opacity: '0.5',
        margin: '0px 0 10px',
    })
    description.cssClass({
        fontSize: '16px',
        margin: '10px 0 30px',
        width: '290px',
    })

    base.cssClass({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    })

    return base
};
