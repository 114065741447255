import state from '../../../../base/services/state'
import helpers from '../../../../helpers'
import { ActionButton } from '../../../shared/action-button'
import { DInput } from '../../../shared/d-input'
import { WizardSlide } from '../../../shared/slide/slide'

export const NameSlide = () => {

    const base = WizardSlide()
    const name = DInput('مشتری جدید', 'نـام و نـام خانوادگی')
    const next = ActionButton(helpers.strings.NEXT_STEP)
    const test = ActionButton('test')
    test.on('click', () => {
        state.set('new-client', { name: 'اردشیر یک' })
        base.emit('next')
    })
    base.append(name, next)

    next.disable()
    next.on('click', handleNext)
    name.on('input', ({ value }: any) => value.length > 0 ? next.enable() : next.disable())
    name.on('key-enter', handleNext)
    base.on('enter', () => setTimeout(name.focus, 450))
    base.on('reset', () => name.clear())

    function handleNext() {
        if (name.getValue().length === 0) return
        state.set('new-client', { name: name.getValue() })
        base.emit('next')
    }

    next.cssClass({
        marginTop: '40px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    return base
}