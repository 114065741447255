import { Div } from "../../base/components/native/div"
import { IRouteParams } from "../../base/lib/router"
import { waitFor } from "../../base/utils/wait"
import helpers from "../../helpers"

export const Page = () => {

    const base = Div()

    base.cssClass(helpers.styles.PAGE_BASE)

    function exit() {
        base.style(helpers.styles.PAGE_EXIT_UP)
    }

    async function enter({ query, from, to, data }: IRouteParams) {
        await waitFor(helpers.styles.PAGE_TRANSITION_DURATION)
        base.style(helpers.styles.PAGE_ENTER)
        base.emit('enter', {query, from, to, data})
    }

    return Object.assign(base, { enter, exit })
}