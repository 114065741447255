import { Wizard } from '../../shared/slide/wizard'
import { DescSlide } from './add-appointment-slides/desc-slide'
import { FeesSlide } from './add-appointment-slides/fees-slide'
import { TimeSlide } from './add-appointment-slides/time-slide'
import { UserSlide } from './add-appointment-slides/user-slide'

export const AddAppointmentFromDayWizard = () => {
    console.log('AddAppointmentFromDayWizard');

    const user = UserSlide()
    const time = TimeSlide()
    const desc = DescSlide()
    const fees = FeesSlide()
    const base = Wizard([user, time, desc, fees])

    return base
}