import { Div } from "../../../../../../base/components/native/div"
import { ITransactionMonth } from "../../../../../../interfaces/transactions"
import { Price } from "../../../../../shared/price"
import { TransactionDay } from "./transaction-day"

export const TransactionMonth = (m: ITransactionMonth) => {
    const base = Div()
    base.cssClass({
        // overflow: 'hidden',
        // overflowY: 'auto',
        // position: 'sticky',
        // top: '0',
    })

    // Row
    const row = Div()
    row.cssClass({
        display: 'grid',
        gridTemplateColumns: '98px 1fr',
        gap: '14px',
        borderBottom: '1px solid #bbb',
        backgroundColor: '#fff',
        position: 'sticky',
        top: '74px',
        zIndex: '2',
        padding: '14px 0 0',
    })
    base.append(row)

    const title = Div(m.month + ' ماه' )
    title.cssClass({
        fontWeight: 'bold',
        fontSize: '16px',
        textAlign: 'left',
    })
    row.append(title)

    // Total
    const value = Price(m.total)
    value.cssClass({
        fontSize: '16px',
        fontWeight: 'bold'
    })
    row.append(value)

    m.byDay.forEach(d => {
        base.append(TransactionDay(d))
    })


    return base
}