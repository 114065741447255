import router, { IRouteParams } from '../../../base/lib/router'
import { Div } from '../../../base/components/native/div'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import { Body } from '../../shared/body'
import services from '../../../services'
import state from '../../../base/services/state'
import { Page } from '../page'
import { AddAppointmentWizard } from '../../shared/add-appointment-wizard/add-appointment-wizard'
import { SetReminderWizard } from './setup-reminder-wizard'
import { Loading } from '../../shared/loading'

export const SetupReminderPage = () => {

    let _state: any = {}
    const base = Page()
    const wizard = SetReminderWizard()
    base.append(wizard)

    wizard.on('done', async () => {
        // await services.appointments.add(state.get('new-appointment'))
        router.back()
        // if (_state.from === '/') return router.back()
        // return router.goto(`/`, { replace: true })
    })

    function exit({ to }: IRouteParams) {
        return base.style(helpers.styles.PAGE_EXIT)
    }

    async function enter({ params, from, to, data }: IRouteParams) {

        const isLoggedIn = await services.auth.check()
        if (!isLoggedIn) return router.goto('/login', { replace: true, from: `clients/${params.clientId}/setup-reminder` })

        wizard.reset()
        state.set('new-reminder', { clientId: params.clientId })
        // _state.clientId = params.clientId
        // _state.from = from
        if (from === '/') base.style(helpers.styles.PAGE_EXIT)
        await waitFor(helpers.styles.PAGE_TRANSITION_DURATION)
        base.style(helpers.styles.PAGE_ENTER)
    }

    return Object.assign(base, { enter, exit })
}