import { ghostify } from "../../base/components/advanced/ghost/ghost";
import { Button } from '../../base/components/native/button';
import { Div } from "../../base/components/native/div";
import { Img } from "../../base/components/native/img";
import { ABSOLUTE, EASE, HIDE, SHOW } from "../../base/helpers/style";
import router from "../../base/lib/router";
import { ActionButton, IActionButton } from "./action-button";
import { Loading } from "./loading";


export const DangerButton = (text: string, options: IActionButton = {}) => {

    const base = ActionButton(text, options)
    const confirm = Div('تایید')
    const cancel = Div('انصراف')
    const keys = Div()
    confirm.style(HIDE)
    cancel.style(HIDE)
    base.el.setAttribute('role', 'button')
    keys.append(confirm, cancel)
    base.append(keys)


    base.el.onclick = (e) => {
        e.stopPropagation()
        confirm.style(SHOW)
        cancel.style(SHOW)
        setTimeout(() => {
            confirm.style(HIDE)
            cancel.style(HIDE)
        }, 2000);
    }

    confirm.el.onclick = (e) => {
        e.stopPropagation()
        base.emit('click')
    }
    cancel.el.onclick = (e) => {
        e.stopPropagation()
        confirm.style(HIDE)
        cancel.style(HIDE)
    }

    keys.cssClass({
        top: '70px',
        display: 'flex',
        flexDirection: 'row',
        gap: '30px',
        position: 'absolute',
        transition: `all 0.5s ${EASE}`
    })

    confirm.cssClass({
        width: 'fit-content',
    })
    cancel.cssClass({
        width: 'fit-content',

    })

    base.cssClass({
        backgroundColor: 'rgb(255 255 255)',
        color: '#ea4c89',
        border: '1px solid #ea4c89',
        overflow: 'visible',

    })

    return Object.assign(base, {
        setLoading(v: boolean) {
            base.el.style.opacity = v ? '0.7' : '1'
            base.el.style.pointerEvents = v ? 'none' : 'auto'
            base.append()
        },
        setText(t: string) {
        },
        enable() {
            base.el.removeAttribute('disabled')
        },
        disable() {
            base.el.setAttribute('disabled', 'true')
        },
        // rotate() {
        //     base.style({ transform: 'rotateX(180deg)', pointerEvents: 'none', backfaceVisibility: 'hidden' })
        // },
        // unrotate() {
        //     base.style({ transform: 'rotateX(0deg)', pointerEvents: 'inherit' })
        // }
    })
};
