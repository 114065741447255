import { Div } from "../../../base/components/native/div";
import { HIDE, SHOW, Y } from "../../../base/helpers/style";
import { ITab } from "./tab";
import { TabsNavItem } from "./tabs-nav-item";

export const TabsNav = (items: ITab[], options = {}) => {

    const base = Div()

    const tabs = items.map(({ id, label, icon, selected }) => {
        const tab = TabsNavItem(label, id, icon, selected)
        tab.on('click', select)
        base.append(tab)
        return tab
    })

    let selected: any = tabs.find(tab => tab.selected) || tabs[0]
    select(selected.id)
    function select(id: string) {
        const found = tabs.find(tab => tab.id === id)
        if (!found || !selected) return
        base.emit('item-selected', id)
        selected.deselect()
        selected = found
        selected.select()
    }

    function reset() {
        selected.deselect()
        selected = tabs.find(tab => tab.selected)
        if (selected) select(selected.id)
    }

    function show() {
        base.style({ ...Y(0), ...SHOW })
    }

    function hide() {
        base.style({ ...Y(60), ...HIDE })
    }

    base.cssClass({
        position: 'absolute',
        bottom: '0', // '0px', // 'calc(env(safe-area-inset-bottom) + 0px)
        right: '0',
        left: '0',
        width: '100%',
        minHeight: '60px',
        height: 'calc(env(safe-area-inset-bottom, 0px)/2 + 60px)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundColor: '#f5f5f5',
        zIndex: '99',
        gap: '30px',
        paddingTop: '8px',
        transition: 'transform 0.3s ease-in-out',
    })

    return Object.assign(base, { select, reset, show, hide })
};
