import { Wizard } from '../../shared/slide/wizard'
import { DateSlide } from './add-appointment-slides/date-slide'
import { DescSlide } from './add-appointment-slides/desc-slide'
import { FeesSlide } from './add-appointment-slides/fees-slide'
import { TimeSlide } from './add-appointment-slides/time-slide'

export const AddAppointmentWizard = () => {

    const date = DateSlide()
    const time = TimeSlide()
    const desc = DescSlide()
    const fees = FeesSlide()
    // const paid = PaidSlide()
    const base = Wizard([date, time, desc, fees])

    return base
}