import { Div } from '../../../base/components/native/div'
import router, { IRouteParams } from '../../../base/lib/router'
import {emitter} from '../../../base/utils/emitter'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import authService from '../../../services/auth'
import { GoogleButton } from './google-button'

// Todo: refactor the page
export const LoginPage = () => {
   
    const base = Div()

    const title = Div('Login')
    title.cssClass({
        fontSize: '18px',
        fontWeight: '900',
        margin: '4px 0 25px 0'
    })

    const google = GoogleButton()

    const contents = Div()
    base.append(contents)
    contents.append(title, google)

    contents.cssClass({
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingLeft: '70px',
        paddingBottom: '50px',
        paddingTop: '16px',
        paddingRight: '50px',
    })

    google.on('request-login', async () => {
        await authService.googleLogin()
    })

    emitter.on('logged-in', (data: any) => {
        // Todo: check if status 0 results fetched
        router.goto('/')
        // setTimeout(() => {
        //    router.goto('/menu')
        // }, 500);
    })

    base.cssClass(helpers.styles.PAGE_BASE)

    return {
        ...base,
        async exit({ to = '' }: IRouteParams) {
            base.style(helpers.styles.PAGE_EXIT)

        },
        async enter({ from = '' }: IRouteParams) {
            await waitFor(helpers.styles.PAGE_TRANSITION_DURATION)
            base.style(helpers.styles.PAGE_ENTER)
        }
    }
}