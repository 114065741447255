import { Div } from "../../../../base/components/native/div"
import { HHMM } from "./hhmm"

export const TimeRange = () => {
    // Todo separate time range hours and minutes
    const base = Div()
    const timeFrom = HHMM()
    const dots = Div('تـا')
    const timeTo = HHMM()
    base.append(timeFrom, dots, timeTo)
    base.cssClass({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        // width: '100%',
        maxWidth: '320px',
        margin: '0 auto',
    })
    dots.cssClass({
        fontSize: '20px',
        margin: '0 25px',
    })
    return Object.assign(base, {
        getValue: () => {
            const from = timeFrom.getValue()
            const to = timeTo.getValue()

            if (!from || !to) return null
            if (typeof from === 'string' && typeof to === 'string' && (from === '--:--' || to === '--:--')) return null
            if (to <= from) return {
                from: to,
                to: from,
            }
            return {
                from,
                to,
            }
        },
        setValue: (value: any) => {
            if (!value) return
            timeFrom.setValue(value.from)
            timeTo.setValue(value.to)
        }
    })

}