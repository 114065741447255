import services from "."
import { emitter } from "../app"
import ldb from "../base/lib/ldb"
import { BaseEventMap, createEmitter } from "../base/utils/emitter"

const flagsEmitter = createEmitter<TFlagsEvents>()
export default {
    all(): { [key in keyof TFlags]: boolean } {
        return ldb.get('FLAGS') || {}
    },
    get(key: keyof TFlags): boolean {
        return ldb.get('FLAGS')[key] || false
    },
    set(key: keyof TFlags, value: boolean) {
        const flags = ldb.get('FLAGS') || {}
        flags[key] = value
        ldb.set('FLAGS', flags)
        flagsEmitter.emit('changed', { flags })
    },
    async init() {
        const numClints = await services.clients.count()
        console.log('numClints', numClints);
        
        // const numAppointments = await services.appointments.count()
        switch (numClints) {
            case 0:
                this.set('CLIENTS_NOT_EMPTY', false)
                break
            case 1:
                this.set('CLIENTS_NOT_EMPTY', true)
                this.set('FIRST_CLIENT_JUST_ADDED', true)
                break
            case 2:
                this.set('CLIENTS_NOT_EMPTY', true)
                this.set('A_FEW_CLIENTS_ADDED', true)
                break
            default:
                this.set('CLIENTS_NOT_EMPTY', true)
                this.set('MORE_CLIENTS_ADDED', true)
                break
        }
    },
    ...flagsEmitter
}

type TFlags = {
    CLIENTS_NOT_EMPTY: boolean
    FIRST_CLIENT_JUST_ADDED: boolean
    A_FEW_CLIENTS_ADDED: boolean
    MORE_CLIENTS_ADDED: boolean
    FIRST_EVENT_ADDED: boolean
    CALENDAR_NOT_EMPTY: boolean
}

interface TFlagsEvents extends BaseEventMap {
    // Todo exclude events from BaseEventMap
    'changed': { flags: { [key in keyof TFlags]: boolean } }
}