import { Wizard } from '../shared/slide/wizard'
import { SetReminderIntroSlide } from './slides/intro'
import { SetReminderFromSlide } from './slides/from'
import { SetReminderMessageSlide } from './slides/message'
import { SetReminderSendSlide } from './slides/send'
import ldb from '../../base/lib/ldb'

export const SetReminderWizard = () => {

    const intro = SetReminderIntroSlide()
    const from = SetReminderFromSlide()
    const message = SetReminderMessageSlide()
    const send = SetReminderSendSlide()
    // const desc = DescSlide()
    // const fees = FeesSlide()
    // const paid = PaidSlide()
    const slides = [intro, from, message, send]
    const businessTitle = ldb.get('my-business-title')
    if (businessTitle) {
        slides.splice(1, 1)
    }
    const base = Wizard(slides)

    return base
}