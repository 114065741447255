import { Div } from '../../../../base/components/native/div'
import { Day } from './day'

export const DayPicker = () => {

    const base = Div()
    const wrapper = Div()
    const topMask = Div()
    const bottomMask = Div()
    const today = new Date()
    const formattedToday = Day(today)
    const todayTitle = Div('امروز')
    formattedToday.append(todayTitle)
    wrapper.append(formattedToday)
    base.append(topMask, bottomMask, wrapper)

    const days = 60
    for (let i = 1; i < days; i++) {
        // from current date to 10 days backword
        const datePrev = new Date()
        datePrev.setDate(datePrev.getDate() - i)
        const formattedPrev = Day(datePrev)
        wrapper.prepend(formattedPrev)
        const dateNext = new Date()

        if (i === 0) continue
        dateNext.setDate(dateNext.getDate() + i)
        const formattedNext = Day(dateNext)
        wrapper.append(formattedNext)
    }

    base.on('mounted', () => {
        wrapper.el.scrollTop = (days - 2) * 60
    })

    const maskStyle = {
        position: 'absolute',
        height: '60px',
        backgroundColor: 'white',
        top: '0',
        right: '0',
        left: '5px',
        zIndex: '99',
        opacity: '.85',
        pointerEvents: 'none',
    }

    todayTitle.cssClass({ color: 'white', backgroundColor: '#00c4ff', padding: '1px 7px 2px', borderRadius: '6px', fontSize: '14px', marginRight: '10px' })
    topMask.cssClass({ ...maskStyle, borderBottom: '1px solid #00000033' })
    bottomMask.cssClass({ ...maskStyle, top: '120px', borderTop: '1px solid #00000033' })
    base.cssClass({
        position: 'relative',
    })
    wrapper.cssClass({
        scrollSnapType: 'y mandatory',
        height: '180px',
        overflowY: 'scroll',
    })

    return Object.assign(base, {
        getValue() {
            const scrolledTop = days - 2 - Math.round(wrapper.el.scrollTop / 60)
            const date = new Date()
            date.setDate(date.getDate() - scrolledTop)
            return date
        },
        setValue(date: Date) {
            console.log('setValue', date)
            
            const diff = date.getDate() - today.getDate()
            wrapper.el.scrollTop = (days - 2) * 60 - diff * 60
        }
    })
}