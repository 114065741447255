import { Div } from "../../../../base/components/native/div";
import { HIDE, SHOW } from "../../../../base/helpers/style";
import router from "../../../../base/lib/router";
import { emitter } from "../../../../services/emitter";
import { PlusIcon } from "../../../shared/plus-icon";

export const HomeHeader = () => {
    const base = Div()

    const add = PlusIcon()
    add.el.onclick = () => router.goto('/clients/add')
    base.append(add)

    base.cssClass({
        height: '60px',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: '60px',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
    })

    console.log('emitter', emitter);
    
    emitter.on('tab-selected', (tab: string) => {
        if (tab === 'clients') {
            add.style(SHOW)
        } else {
            add.style(HIDE)
        }
    })


    return base
};
