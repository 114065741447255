import { Wizard } from '../../shared/slide/wizard'
import { NameSlide } from './add-client-slides/name-slide'
import { PhoneSlide } from './add-client-slides/phone-slide'
import { TelegramSlide } from './add-client-slides/telegram-slide'

export const AddClientWizard = () => {
    
    const nameSlide = NameSlide()
    const phoneSlide = PhoneSlide()
    const telegramSlide = TelegramSlide()
    const base = Wizard([nameSlide, phoneSlide, telegramSlide])

    return base
}