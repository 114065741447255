import { Div } from '../../../../base/components/native/div'
import { RangeMask } from './range-mask'
import { RangeWrapper } from './range-wrapper'
import { Time } from './time'

export const HHPicker = () => {

    const base = Div()
    const wrapper = RangeWrapper()
    const mask = RangeMask()
    base.append(mask, wrapper)

    const hours = 24
    for (let i = 0; i < hours; i++) {
        let hour = ('0' + i).slice(-2)
        if (hour === '24') hour = '00'
        const time = Time(`${hour}`)
        time.style({ fontWeight: 'bold' })
        wrapper.append(time)
    }
    wrapper.append(Time(''))

    base.on('mounted', () => {
        wrapper.el.scrollTop = (hours - 12) * 60
    })

    base.cssClass({
        position: 'relative',
    })

    return Object.assign(base, {
        getValue() {
            const scrolledTop = hours - Math.round(wrapper.el.scrollTop / 60)
            let calculatedHours = 24 - scrolledTop
            return ('0' + calculatedHours).slice(-2)
        },
        setValue(time: string) {
            let [hour, minute] = time.split(':')
            let index = 0
            if (hour === '00') index = hours + 1
            else index = parseInt(hour) * 12 + parseInt(minute) / 5
            console.log('index', index);
            setTimeout(() => {
                wrapper.el.scrollTop = index * 60
            }, 100);
        }
    })
}