import { Page } from '../page'
import ldb from '../../../base/lib/ldb'
import { ClientsTab } from './parts/clients-tab'
import { CalendarTab } from './parts/calendar-tab'
import { Tabs } from '../../shared/tabs/tabs'
import { ETCTab } from './parts/etc-tab'
import services from '../../../services'
import flags from '../../../services/flags'
import { HomeHeader } from './parts/home-header'

export const HomePage = () => {

    const base = Page()
    base.append()

    const header = HomeHeader()
    base.append(header)

    const clients = ClientsTab()
    const calendar = CalendarTab()
    const tabs = Tabs([clients, calendar])
    tabs.hideNav()
    base.append(tabs)

    // emitter.on('flags-changed', ({ flags }) => {
    //     if (flags.FIRST_CLIENT_ADDED) {
    //         base.empty()
    //         base.append(FirstClientAdded())
    //     } else {
    //         base.empty()
    //         base.append(AddFirstClient())
    //     }
    // })

    base.on('enter', () => {
        tabs.showNav()
        clients.refresh()
        calendar.refresh()
    })

    return base
}