import { ghostify } from '../../../../../../base/components/advanced/ghost/ghost'
import { Base } from '../../../../../../base/components/base'
import { Div } from '../../../../../../base/components/native/div'
import { Img } from '../../../../../../base/components/native/img'
import { jDateFormatter } from '../../../../../../base/helpers/date'
import { NUMBERS_DELIMITER_REGEX } from '../../../../../../base/helpers/regex'
import { EASE } from '../../../../../../base/helpers/style'
import router from '../../../../../../base/lib/router'
import images from '../../../../../../configs/images'
import helpers from '../../../../../../helpers'
import locale from '../../../../../../helpers/locale'
import { TAppointment } from '../../../../../../interfaces/appointments'
import { Price } from '../../../../../shared/price'
import { AppointmentReminder } from '../../../../../shared/appointment-reminder/appointment-reminder'
import { AppointmentNotify } from './appointment-notify'
import { AppointmentRepeat } from './appointment-repeat'

export const AppointmentItem = (item: TAppointment, options: any = {}) => {
    const base = Base()
    const date = Div(jDateFormatter.format(item.date).replace(',', '').split(' ').reverse().join(' '))
    const time = Div(`از  ${locale.replaceLatinDigits(item.time?.from)} تا ${locale.replaceLatinDigits(item.time?.to)}`)
    const fees = Price(item.fees)
    const description = Div(item.description)
    ghostify(base, { bg: '#fff' })
    base.el.onclick = () => router.goto(`/clients/${item.clientId}/appointments/${item.id}`)
    base.append(date, time, description)
    if (item.fees) base.append(fees)
    const repeat = AppointmentRepeat(item)
    repeat.on('repeat', () => base.emit('repeat'))
    // base.append(repeat)
    const more = Div('مشاهده و ویرایش')
    more.cssClass({
        fontSize: '14px',
        color: '#969696',
        marginTop: '10px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '5px',
    })
    // more.el.onclick = () => router.goto(`/clients/${item.clientId}/appointments/${item.id}`)
    base.append(more)

    const arrow = Img(images.ICONS.ARROW_LEFT, { width: 16 })
    more.append(arrow)
    if (options.notify) {
        base.append(AppointmentNotify(item))
    }
    const circle = Div()
    circle.cssClass({
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: '#fff',
        border: '2px solid #ea4c89',
        position: 'absolute',
        top: '7px',
        right: '-6px',
    })
    base.append(circle)
    // const borderColor = item.date > new Date() ? '#ea4c89' : new Date(item.date).toDateString() === new Date().toDateString() ? '#64ddc0' : '#ccc'

    // const reminder = AppointmentReminder(item)
    // base.append(reminder)

    base.cssClass({
        ...EASE(.16),
        margin: '0px 60px 30px 20px',
        // backgroundColor: '#64ddc014',
        borderRight: `2px ${item.date > new Date() ? 'dashed' : 'solid'} #ea4c89`,
        // borderRadius: '16px',
        padding: '0 12px 5px',

        cursor: 'pointer',
        textAlign: 'right',
        position: 'relative',
    })
    description.cssClass({
        fontSize: '16px',
        margin: '15px 0',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical'

    })
    date.cssClass({
        fontSize: '14px',
        color: '#666',
        marginBottom: '5px'
    })
    time.cssClass({
        fontSize: '14px',
        color: '#666',
        marginBottom: '5px',
        opacity: '0.6'
    })
    fees.cssClass({
        fontSize: '14px',
        color: '#ea4c89',
        marginBottom: '5px'
    })
    return base
}