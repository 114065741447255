import { Div } from "../../../../base/components/native/div"
import { Img } from "../../../../base/components/native/img"
import router from "../../../../base/lib/router"
import images from "../../../../configs/images"
import { ActionButton } from "../../../shared/action-button"

export const AddFirstClient = () => {

    // Graphic test
    const graphic = Img(images.BANNERS.HAPPY_CLIENT, { width: 256 })

    // Title test
    const title = Div('فـلِـپـــ')
    title.cssClass({
        fontSize: '52px',
        fontWeight: 'bold',
    })

    // Description test
    const description = Div(`با فلپ با مشتریان خود در ارتباط باشید و جلسات یا نوبت‌های آینده را بصورت خودکار به آنها یادآوری کنید.`)
    description.cssClass({
        fontSize: '16px',
        margin: '10px 0 30px',
        width: '260px',
    })

    // Add button test
    const add = ActionButton('افزودن اولین مشتری یا مراجع', { icon: images.ICONS.ADD })
    add.cssClass({
        width: 'fit-content',
    })
    add.el.onclick = () => router.goto('/clients/add')

    // Rename test
    const rename = Div('برای راهنمایی و آشنایی با قسمت‌های مختلف برنامه اینجا کلیک کنید یا به صفحه درباره برنامه بروید.')
    rename.cssClass({
        fontSize: '12px',
        color: '#666',
        margin: '20px 0 0',
        width: '260px',
        marginTop: '20px',
    })

    // Base test
    const base = Div()
    base.cssClass({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '40px'
    })

    // Main
    base.append(graphic, title, description, add, rename)

    return base
}
