import { A } from '../../../../base/components/native/a'
import { Div } from '../../../../base/components/native/div'
import state from '../../../../base/services/state'
import helpers from '../../../../helpers'
import strings from '../../../../helpers/strings'
import { ActionBack } from '../../../shared/action-back'
import { ActionButton } from '../../../shared/action-button'
import { DInput } from '../../../shared/d-input'
import { WizardSlide } from '../../../shared/slide/slide'

export const BotSlide = () => {

    const base = WizardSlide()

    const desc = Div(`
       لطفاً کد تأیید را از طریق ربات تلگرامی دریافت کرده و وارد کنید. برای دریافت کد، کافی است روی لینک زیر کلیک کرده و عضو ربات تلگرامی فلپ شوید.
        `)
    desc.cssClass({
        margin: '30px 0',
        // display: 'none'
    })
    const link = A()
    link.cssClass({
        display: 'block',
        margin: '10px auto',
        textAlign: 'center',
        fontSize: '20px',
        color: '#ea4c89',
        backgroundColor: '#ea4c8912',
        padding: '10px',
        borderRadius: '20px',
        textDecoration: 'none',
    })
    base.append(desc)
    const prev = ActionBack(strings.PREV_STEP)
    prev.on('click', () => base.emit('prev'))
    base.on('enter', () => { link.setValue('https://t.me/flepuserbot?start=' + state.get('login-request').joinToken + '-OTP') })
    base.append(desc, link, prev)
    link.el.onclick = () => {
        base.emit('next')
    }

    function handleNext() {
        base.emit('next')
    }

    prev.cssClass({
        marginTop: '20px',
    })

    base.cssClass({
        padding: '15px 60px',
    })

    return base
}