import { Base } from "../../../base/components/base"
import { Div } from "../../../base/components/native/div"
import { ActionBack } from "../../shared/action-back"
import { ActionButton } from "../../shared/action-button"
import { DInput } from "../../shared/d-input"

export const EditText = (label: string,  value: string) => {

    const base = Base()
    const desc = DInput(label, value, { size: 20, type: 'textarea' })
    const next = ActionButton('تایید')
    const prev = ActionBack('لغو')
    const keys = Div()
    keys.append(prev, next)
    base.append(desc, keys)
    if (value.length > 3) next.enable()
    base.on('mounted', () => desc.focus())
    desc.on('input', (value: any) => value.length > 3 ? next.enable() : next.disable())
    next.on('click', () => base.emit('update'))
    prev.on('click', () => base.emit('cancel'))
    desc.setValue(value)

    keys.cssClass({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '40px',
        gap: '16px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    return Object.assign(base, {
        getValue() {
            return desc.getValue().trim()
        }
    })
}