import { Div } from '../../../../base/components/native/div'
import { jDateFormatter } from '../../../../base/helpers/date';
import { CENTER } from '../../../../base/helpers/style';

export const Day = (date: Date) => {
    const base = Div()


    const formattedDate = jDateFormatter.format(date);
    const [weekDay, day, ...rest] = formattedDate.replace(',', '').split(' ').reverse()
    const weekDayDiv = Div(weekDay)
    const dayDiv = Div(day)
    const dateDiv = Div(rest.join(' '))
    base.append(weekDayDiv, dayDiv, dateDiv)

    weekDayDiv.cssClass({
        width: '65px'
    })
    dayDiv.cssClass({
        fontSize: '28px',
        fontWeight: 'bold',
        width: '45px',
        textAlign: 'center'
    })
    base.cssClass({
        height: '60px',
        ...CENTER,
        paddingTop: '2px',
        flexDirection: 'row',
        justifyContent: 'center',
        fontSize: '18px',
        scrollSnapAlign: 'start',
        position: 'relative'
    })

    return base
}