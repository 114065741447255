import { Wizard } from '../../shared/slide/wizard'
import { DateSlide } from './slides/date'
import { DescSlide } from './slides/desc'
import { FeesSlide } from './slides/fees'
import { TimeSlide } from './slides/time'

export const AddAppointmentWizard = () => {

    const date = DateSlide()
    const time = TimeSlide()
    const desc = DescSlide()
    const fees = FeesSlide()
    // const paid = PaidSlide()
    const base = Wizard([date, time, desc, fees])

    return base
}