import { ghostify } from "../../base/components/advanced/ghost/ghost";
import { Button } from '../../base/components/native/button';
import { Div } from "../../base/components/native/div";
import { Img } from "../../base/components/native/img";
import { ABSOLUTE, EASE } from "../../base/helpers/style";
import router from "../../base/lib/router";
import images from "../../configs/images";
import { Loading } from "./loading";

export interface IActionButton {
    icon?: string
    target?: string
    description?: string
    background?: string
    type?: 'default' | 'danger' | 'success'
}

export const ActionButton = (text: string, options: IActionButton = {}) => {

    const base = Button()
    const title = Div(text)
    const loading = Loading('white', 60)
    const confirm = Img(images.ICONS.CONFIRM, { width: 32 })
    base.el.setAttribute('role', 'button')
    base.append(title, loading, confirm)

    if (options.icon) {
        const icon = Img(options.icon, { width: 18 })
        base.prepend(icon)
        title.cssClass({ marginRight: '8px', marginBottom: '2px' })
    }

    ghostify(base, { bg: 'white' })

    confirm.cssClass({
        display: 'none',
    })
    loading.cssClass({
        display: 'none'
    })
    base.cssClass({
        // width: '100%',
        height: '56px',
        overflow: 'hidden',
        border: 'none',
        position: 'relative',
        padding: '10px 22px',
        minWidth: 'calc(50% - 8px)',
        // maxWidth: 'fit-content',
        width: '100%',
        flexShrink: '0',
        backgroundColor: options.background || '#EA4C89',
        justifyContent: 'center',
        color: 'white',
        borderRadius: '30px',
        textAlign: 'center',
        userSelect: 'none',
        fontSize: '18px',
        boxShadow: '#0000002b 0px 3px 5px',
        display: 'flex',
        alignItems: 'center',
        transition: 'filter 0.2s, box-shadow 0.08s, transform 0.08s, background-color 0.2s',
        '&:active': {
            filter: 'brightness(0.95) contrast(1.1)',
            boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)',
            transform: 'scale(0.99)',
        },
        '&:disabled': {
            opacity: '0.7',
            backgroundColor: '#eee',
            pointerEvents: 'none',
            boxShadow: 'none',
            color: '#b3b3b3'
        }
    })

    return Object.assign(base, {
        wait() {
            base.style({ pointerEvents: 'none', opacity: '0.7' })
            title.style({ display: 'none' })
            loading.style({ display: 'block' }, 400)
        },
        done() {
            base.style({ pointerEvents: 'auto', opacity: '1' })
            title.style({ display: 'block' }, 1500)
            loading.style({ display: 'none' })
        },
        confirm() {
            base.style({ pointerEvents: 'auto', opacity: '1' })
            loading.style({ display: 'none' })
            title.style({ display: 'block' }, 1500)
            confirm.style({ display: 'block' })
            confirm.style({ display: 'none' }, 1500)
        },
        setLoading(v: boolean) {
            base.el.style.opacity = v ? '0.7' : '1'
            base.el.style.pointerEvents = v ? 'none' : 'auto'
            title.el.innerText = v ? 'در حال ذخیره ' : text
            loading.style({ display: v ? 'block' : 'none' })
            base.append()
        },
        setText(t: string) {
            title.el.innerText = t
        },
        enable() {
            base.el.removeAttribute('disabled')
        },
        disable() {
            base.el.setAttribute('disabled', 'true')
        },
        // rotate() {
        //     base.style({ transform: 'rotateX(180deg)', pointerEvents: 'none', backfaceVisibility: 'hidden' })
        // },
        // unrotate() {
        //     base.style({ transform: 'rotateX(0deg)', pointerEvents: 'inherit' })
        // }
    })
};
