import { Div } from "../../../../base/components/native/div"
import router from "../../../../base/lib/router"
import images from "../../../../configs/images"
import services from "../../../../services"
import flags from "../../../../services/flags"
import { ActionButton } from "../../../shared/action-button"
import { ITab, Tab } from "../../../shared/tabs/tab"
import { AddFirstClient } from "./add-first-client"
import { ClientList } from "./clients-list"
import { FirstClientAdded } from "./first-client-added"

export const ClientsTab = (): ITab => {
    const base = Tab()

    function refresh() {
        base.empty()
        const { FIRST_CLIENT_JUST_ADDED, CLIENTS_NOT_EMPTY, A_FEW_CLIENTS_ADDED, CALENDAR_NOT_EMPTY } = services.flags.all()
        
        // No clients
        if (!CLIENTS_NOT_EMPTY) {
            const addFirstClient = AddFirstClient()
            base.append(addFirstClient)
            return
        }

        // Just added a client
        if (FIRST_CLIENT_JUST_ADDED) {
            const firstClientAdded = FirstClientAdded()
            base.append(firstClientAdded)
            flags.set('FIRST_CLIENT_JUST_ADDED', false)
        }

        // Title
        // if (!FIRST_CLIENT_JUST_ADDED && !CALENDAR_NOT_EMPTY) {
        //     const title = Div('مشتری‌ها')
        //     title.cssClass({
        //         fontSize: '24px',
        //         fontWeight: 'bold',
        //         width: '100%',
        //         textAlign: 'center',
        //         margin: '0 0 20px',
        //     })
        //     base.append(title)
        // }

        // Load clients
        const list = ClientList()
        base.append(list)
        list.load()

        // If less than 5 clients, show the add button
        const addMoreClients = ActionButton('افزودن مشتری دیگر', { icon: images.ICONS.ADD })
        addMoreClients.on('click', () => router.goto('/clients/add'))
        addMoreClients.cssClass({
            width: 'fit-content',
            margin: '50px auto 0',
        })
        if (CLIENTS_NOT_EMPTY && !A_FEW_CLIENTS_ADDED) {
            base.append(addMoreClients)
        }
    }

    return Object.assign(base, {
        onFocus() {
            console.log('Clients Tab entered')
        },
        onBlur() {
            console.log('Clients Tab exited')
        },
        refresh,
        label: 'مشتری‌ها',
        id: 'clients',
        icon: images.ICONS.CLIENTS
    })
}

