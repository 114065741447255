import appointments from './appointments'
import auth from './auth'
import clients from './clients'
import db from './db'
import flags from './flags'
import transactions from './transactions'

export default {
    db,
    clients,
    appointments,
    flags,
    transactions,
    auth
}