import { Div } from '../../../base/components/native/div'
import { EASE } from '../../../base/helpers/style'
import ldb from '../../../base/lib/ldb'
import router, { IRouteParams } from '../../../base/lib/router'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import db from '../../../services/db'
import { About } from './about'


export const MenuPage = () => {

    const base = Div()
    const contents = Div()
    const about = About()
    // const login = Div('Login')
    // const loggedIn = Div()
    // const logout = Div('Logout')
    contents.append(about)
    base.append(contents)

    // login.el.onclick = () => router.goto('/login')
    // logout.el.onclick = () => {
    //     ldb.clear()
    //     db.clear('flep-db')
    //     login.style({ display: 'block' })
    //     loggedIn.style({ display: 'none' })
    //     logout.style({ display: 'none' })
    //     router.goto('/')
    // }

    base.cssClass(helpers.styles.PAGE_BASE)
    // title.cssClass({ margin: '0 0 21px 0' })
    // login.cssClass({ margin: '0 0 21px 0', cursor: 'pointer' })
    // loggedIn.cssClass({ margin: '0 0 21px 0', fontSize: '16px' })
    // logout.cssClass({ margin: '0 0 21px 0', cursor: 'pointer' })
    contents.cssClass({
        fontSize: '20px',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingLeft: '70px',
        paddingBottom: '50px',
        paddingTop: '18px',
        paddingRight: '50px',
        height: '100%',
        
    })

    return {
        ...base,
        async exit({ to = '' }: IRouteParams) {
            base.style(helpers.styles.PAGE_EXIT)
            if (to === '/login') base.style(helpers.styles.PAGE_EXIT_UP)
        },
        async enter({ from = '' }: IRouteParams) {
            await waitFor(200)
            if (from === '/') {
                base.style({...helpers.styles.PAGE_EXIT, ...EASE(0)})
            }
            base.style({...helpers.styles.PAGE_ENTER, ...EASE(.16)}, 50)
            
            const user = ldb.get('rankr-user')?.profile?.email
            if (user !== undefined && user !== null && user !== '') {
                // login.style({ display: 'none' })
                // loggedIn.style({ display: 'block' })
                // logout.style({ display: 'block' })
                // loggedIn.html(`Logged in as: <br>${user}`)
            } else {
                // login.style({ display: 'block' })
                // loggedIn.style({ display: 'none' })
                // logout.style({ display: 'none' })
            }
        }
    }
}