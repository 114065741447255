import { Base, IBaseComponent } from "../../../base/components/base"

export const Tab = () => {

    const base = Base()

    base.on('mounted', () => {
        const D = base.el.parentElement?.getBoundingClientRect()
        if (!D) return
        base.style({ height: D.height + 'px' })
    })

    base.el.addEventListener('scroll', (e) => {
        // if (force.isTouching) return
        // force.isScrolling = true
    }, { capture: true, passive: true })

    base.cssClass({
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        overflowY: 'auto',
    })

    return base
}

export interface ITab extends IBaseComponent<any> {// TODO: Define the type of the data and events
    onFocus?: () => void
    onBlur?: () => void
    refresh: (...args: any) => void
    id: string
    label: string
    icon: string
}