import ldb from '../base/lib/ldb'
import { XHR } from '../base/lib/xhr'
import { emitter } from '../base/utils/emitter'

let popup: Window

export default {
    async googleLogin() {
        const W = Math.max(window.innerWidth, 600)
        const H = Math.max(window.innerHeight, 800)
        const { data } = await XHR.get('/auth/google', { type: 'text/html' })
        popup = <Window>window.open('', 'Rankr authentication', `status=1, height=${H}, width=${W}, toolbar=0, resizable=0`)
        popup.location.href = data
        return true
    }
}

window.addEventListener('message', (event) => {
    return // temp
    if (event?.origin !== window.origin) return
    popup?.close()
    getProfile(event.data)
    return true
}, false)

async function getProfile(token: string) {
    const { data } = await XHR.get('/auth/google/callback?code=' + token)
    ldb.save(data).as('rankr-user')
    emitter.emit('logged-in', data)
}