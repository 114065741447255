import { Base } from '../../base/components/base'
import { Div } from "../../base/components/native/div"
import { EASE } from '../../base/helpers/style'

export const PageHeader = (title = '') => {
    const base = Div(title)
    base.cssClass({
        position: 'absolute',
        top: '0',
        right: '0',
        left: '0',
        zIndex: '2',
        height: '60px',
        backgroundColor: '#fff',//COLORS.MAIN,
        // boxShadow: '0 0 6px #00000020',
        fontSize: '20px',
        fontWeight: 'bold',
        padding: '15px 60px 17px 0px',
        textAlign: 'right',
        ...EASE(.24)
    })


    return Object.assign(base, {
        toggleShadow(v: number) {
            base.style({ boxShadow: v > 10 ? '#00000012 0px 5px 8px' : '#00000000 0px 5px 8px' })
        },
    })
}