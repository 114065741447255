import { Div } from '../../../base/components/native/div'
import ldb from '../../../base/lib/ldb'
import state from '../../../base/services/state'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import services from '../../../services'
import { ActionBack } from '../../shared/action-back'
import { ActionButton } from '../../shared/action-button'
import { DInput } from '../../shared/d-input'
import { WizardSlide } from '../../shared/slide/slide'

export const VerifySlide = () => {

    const base = WizardSlide()
    const code = DInput('لطفا کد دریافتی را وارد کنید', '----', { size: 36, pattern: '[0-9]*', inputmode: 'numeric', dir: 'ltr', isLatin: true })
    const error = Div('کد وارد شده صحیح نیست').style({ color: 'red', display: 'none' })
    const next = ActionButton('ورود')
    const prev = ActionBack(helpers.strings.PREV_STEP)
    const keys = Div()
    keys.append(next, prev)
    base.append(code, error, keys)

    next.disable()
    next.on('click', handleNext)
    prev.on('click', () => base.emit('prev'))
    code.on('input', ({ value }: any) => value.length > 0 ? next.enable() : next.disable())
    base.on('enter', () => setTimeout(() => code.focus(), 450))
    base.on('reset', () => code.clear())
    code.on('key-enter', handleNext)

    async function handleNext() {
        if (code.getValue().length === 0) return
        next.wait()
        await waitFor(500)
        const c = helpers.locale.replacePersianDigits(code.getValue())

        const loggedIn = await services.auth.login(state.get('login-request').phone, c)
        if (!loggedIn) {
            error.style({ display: 'block' })
            return
        }
        error.style({ display: 'none' })
        console.log({ loggedIn });
        //
        next.confirm()
        await waitFor(1000)
        base.emit('done')
    }

    next.cssClass({
        backgroundColor: '#1cd4a2'
    })
    keys.cssClass({
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-between',
        marginTop: '40px',
        gap: '16px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    return base
}