import { ghostify } from "../../../base/components/advanced/ghost/ghost"
import { Div } from "../../../base/components/native/div"
import { Img } from "../../../base/components/native/img"
import { CENTER } from "../../../base/helpers/style"
import images from "../../../configs/images"

export const Arrow = ({ direction = 'down' }) => {
    const base = Div()
    base.cssClass({
        ...CENTER,
        padding: '19px',
        position: 'absolute',
        backgroundColor: 'white',
        top: '0px',
        right: '0px',
    })
    ghostify(base, { bg: '#eee' })

    const arrow = Img(images.ICONS.DOWN_ARROW, { width: 20 })
    base.append(arrow)

    return base
}