import { Wizard } from "../../shared/slide/wizard"
import { DescSlide } from "./slides/desc-slide"
import { FeesSlide } from "./slides/fees-slide"


export const AddIncomeWizard = () => {
    
    const fees = FeesSlide()
    const desc = DescSlide()
    // const telegramSlide = TelegramSlide()
    const base = Wizard([fees, desc])

    return base
}