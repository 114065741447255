import { Div } from "../../../base/components/native/div";
import { Img } from "../../../base/components/native/img";
import router, { IRouteParams } from "../../../base/lib/router";
import images from "../../../configs/images";
import helpers from "../../../helpers";
import { ActionButton } from "../../shared/action-button";
import { Body } from "../../shared/body";
import { PageHeader } from "../../shared/page-header";
import { Page } from "../page";
import { LoginWizard } from "./login-wizard";

export const LoginPage = () => {
    const base = Page()

    const wizard = LoginWizard()
    base.append(wizard)
    wizard.on('done', async () => {


        router.back()
    })
    const desc = Div('برای ارسال یادآور لازم است با استفاده از شماره تلگرام خود وارد برنامه شوید.')
    desc.cssClass({
        margin: '60px',
        display: 'none'
    })
    base.append(desc)

    function exit({ to }: IRouteParams) {
        return base.style(helpers.styles.PAGE_EXIT)
    }


    base.on('enter', ({ from }: IRouteParams) => {
        if (from?.includes('setup-reminder')) {
            desc.style({ display: 'block' })
        }
        wizard.reset()
    })

    return Object.assign(base, { exit })
};
