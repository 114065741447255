import { Div } from "../../../../base/components/native/div";
import router from "../../../../base/lib/router";


export const ClientListItem = (client: { name: string, phone: string, id: string, _id: string }) => {

    const name = Div(client.name)

    const profile = Div(client.name[0])
    profile.cssClass({
        width: '64px',
        height: '64px',
        borderRadius: '50%',
        backgroundColor: '#eee',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        marginBottom: '7px',
    })

    const base = Div()
    base.append(profile, name)

    base.el.onclick = () => {   
        router.goto(`/clients/${client._id || client.id}`)
    }

    base.cssClass({
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '24px 0',
    })

    return base
};
