import router, { IRouteParams } from '../../../base/lib/router'
import { Div } from '../../../base/components/native/div'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import { Body } from '../../shared/body'
import services from '../../../services'
import state from '../../../base/services/state'
import { AddAppointmentWizard } from './add-appointment-wizard'

export const AddAppointmentPage = () => {

    let _state: any = {}
    const base = Div()
    const body = Body()
    const wizard = AddAppointmentWizard()
    body.append(wizard)
    base.append(body)

    body.cssClass({ display: 'flex', flexDirection: 'column', alignItems: 'center' })
    base.cssClass(helpers.styles.PAGE_BASE)

    wizard.on('done', async () => {
        await services.appointments.add(state.get('new-appointment'))
        router.back()
        // if (_state.from === '/') return router.back()
        // return router.goto(`/`, { replace: true })
    })

    function exit({ to }: IRouteParams) {
        return base.style(helpers.styles.PAGE_EXIT)
    }

    async function enter({ params, from, to, data }: IRouteParams) {
        wizard.reset()
        state.set('new-appointment', { clientId: params.clientId })
        _state.clientId = params.clientId
        _state.from = from
        if (from === '/') base.style(helpers.styles.PAGE_EXIT)
        await waitFor(helpers.styles.PAGE_TRANSITION_DURATION)
        base.style(helpers.styles.PAGE_ENTER)
    }

    return Object.assign(base, { enter, exit })
}