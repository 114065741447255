import { Div } from '../../../base/components/native/div'
import state from '../../../base/services/state'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import strings from '../../../helpers/strings'
import services from '../../../services'
import { ActionButton } from '../../shared/action-button'
import { DInput } from '../../shared/d-input'
import { WizardSlide } from '../../shared/slide/slide'

export const TelegramSlide = () => {

    const base = WizardSlide()
    const tele = DInput('ورود به فلپ', '09123467890', { size: 36, pattern: '[0-9]*', inputmode: 'numeric' })

    const desc = Div(`
        برای ارسال یادآور به مشتری، لطفاً وارد برنامه شوید. نام کاربری شما همان شماره تلفنی است که در
        <b>
         تلگرام 
        </b>
        ثبت کرده‌اید. لطفاً شماره خود را وارد کنید و منتظر دریافت کد ورود بمانید.
        `)
    desc.cssClass({
        margin: '30px 0',
        // display: 'none'
    })
    // base.append(desc)

    const next = ActionButton(strings.NEXT_STEP)
    base.append(tele, desc, next)

    next.disable()
    next.on('click', handleNext)
    tele.on('input', ({ value }: any) => isValueValid(value) ? next.enable() : next.disable())
    tele.on('key-enter', handleNext)
    base.on('enter', () => setTimeout(tele.focus, 450))
    base.on('reset', () => tele.clear())

    async function handleNext() {
        if (tele.getValue().length === 0) return
        next.wait()
        const formattedNumber = helpers.locale.formatToInternationalPhoneNumber(tele.getValue())
        // const formattedNumber = '31684643729'
        await waitFor(1000)
        const results = await services.auth.requestLogin(formattedNumber)

        next.done()
        if (results.data.joined) {
            state.set('login-request', { phone: formattedNumber })
            base.emit('skip')
            return
        }

        state.set('login-request', { ...results.data, phone: formattedNumber })
        base.emit('next')
    }

    next.cssClass({
        marginTop: '40px',
    })

    function isValueValid(value: string) {
        return !!value.match(/^۰۹[۰-۹]{9}$/)
    }

    base.cssClass({
        padding: '15px 60px',
    })

    return base
}