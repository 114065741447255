import services from "."
import { jDateFormatter } from "../base/helpers/date"
import { TAppointment } from "../interfaces/appointments"
import { TClient } from "../interfaces/client"
import { IAggregatedTransaction } from "../interfaces/transactions"

export default {
    async all(client: TClient) {
        const appointments = await services.appointments.byClient(client.id)
        // appointments.sort((a: any, b: any) => a.date > b.date ? -1 : 1)

        // let total = appointments.reduce((acc: number, appointment: TAppointment) => acc + appointment.fees, 0)

        appointments.sort((a: any, b: any) => a.date > b.date ? -1 : 1)
        const data: IAggregatedTransaction = appointments.reduce((acc: IAggregatedTransaction, appointment: TAppointment) => {
            const [year, month, day, wd] = jDateFormatter.format(appointment.date).replace(',', '').split(' ')
            acc.total += parseInt(appointment.fees)
            acc.count++
            let y = acc.byYear[acc.byYear.length - 1]
            if (y) {
                y.total += parseInt(appointment.fees)
                y.count++
            } else {
                y = {
                    year,
                    total: parseInt(appointment.fees),
                    count: 1,
                    byMonth: []
                }
                acc.byYear.push(y)
            }
            let m = y.byMonth[y.byMonth.length - 1]
            if (m) {
                if (m.month === month) {
                    m.total += parseInt(appointment.fees)
                    m.count++
                } else {
                    m = {
                        month,
                        total: parseInt(appointment.fees),
                        count: 1,
                        byDay: []
                    }
                    y.byMonth.push(m)
                }
            } else {
                m = {
                    month,
                    total: parseInt(appointment.fees),
                    count: 1,
                    byDay: []
                }
                y.byMonth.push(m)
            }
            let d = m.byDay[m.byDay.length - 1]
            if (d) {
                if (d.day === day) {
                    d.total += parseInt(appointment.fees)
                    d.count++
                    d.items.push({ id: appointment.id, fees: appointment.fees, at: appointment.at })
                } else {
                    d = {
                        wd,
                        day,
                        total: parseInt(appointment.fees),
                        count: 1,
                        items: [{ id: appointment.id, fees: appointment.fees, at: appointment.at }]
                    }
                    m.byDay.push(d)
                }
            } else {
                d = {
                    wd,
                    day,
                    total: parseInt(appointment.fees),
                    count: 1,
                    items: [{ id: appointment.id, fees: appointment.fees, at: appointment.at }]
                }
                m.byDay.push(d)
            }
            return acc
            console.log({ wd, day, month, year })

        }, { total: 0, count: 0, byYear: [] })

        return data
    }
}