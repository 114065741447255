import { Div } from "../../../../base/components/native/div"
import state from "../../../../base/services/state"
import helpers from "../../../../helpers"
import { ActionBack } from "../../../shared/action-back"
import { ActionButton } from "../../../shared/action-button"
import { TimeRange } from "../../../shared/calendar/time-picker/time-range"
import { WizardSlide } from "../../../shared/slide/slide"


export const TimeSlide = () => {

    const base = WizardSlide()
    const name = Div('زمان مراجعه')
    const time = TimeRange()
    const next = ActionButton(helpers.strings.NEXT_STEP)
    const prev = ActionBack(helpers.strings.PREV_STEP)
    const keys = Div()
    keys.append(prev, next)
    base.append(name, time, keys)

    next.on('click', () => {
        state.merge('new-appointment', { time: time.getValue() })
        base.emit('next')
    })
    prev.on('click', () => base.emit('prev'))
    // next.on('click', () => { state.merge('new-appointment', { date: date.getValue() }); console.log(date.getValue()); base.emit('next') })
    // base.on('enter', () => setTimeout(name.focus, 450))
    // base.on('reset', () => name.clear())
    name.cssClass({
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '10px',
    })
    keys.cssClass({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '30px',
        gap: '16px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    return base
}