import { Div } from "../../../../base/components/native/div"
import state from "../../../../base/services/state"
import helpers from "../../../../helpers"
import locale from "../../../../helpers/locale"
import { ActionBack } from "../../../shared/action-back"
import { ActionButton } from "../../../shared/action-button"
import { DInput } from "../../../shared/d-input"
import { WizardSlide } from "../../../shared/slide/slide"


export const FeesSlide = () => {

    const base = WizardSlide()
    const paid = DInput('مبلغ دریافتی)', '250,000', { size: 32, pattern: '[0-9]*', inputmode: 'numeric' })
    const next = ActionButton('ذخیره')
    const prev = ActionBack(helpers.strings.PREV_STEP)
    const keys = Div()
    keys.append(next, prev)
    base.append(paid, keys)

    next.disable()
    next.on('click', () => {
        let fees = locale.replacePersianDigits(paid.getValue())
        if (isNaN(parseInt(fees))) fees = '0'
        state.merge('new-income', { paid: parseInt(fees) })
        base.emit('next')
    })
    prev.on('click', () => base.emit('prev'))
    paid.on('input', ({ value }: any) => value.length > 0 ? next.enable() : next.disable())
    base.on('enter', () => setTimeout(() => paid.focus(), 450))
    base.on('reset', () => paid.clear())

    next.cssClass({
        backgroundColor: '#4ceacd'
    })
    keys.cssClass({
        display: 'flex',
        flexFlow: 'row-reverse',
        justifyContent: 'space-between',
        marginTop: '40px',
        gap: '16px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    return base
}