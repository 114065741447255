import { Base } from "../../../base/components/base"
import { Div } from "../../../base/components/native/div"
import { EASE, X } from "../../../base/helpers/style"
import { ITab } from "./tab"

let force: any = {
    isTouching: false,
    isScrolling: false
}
// let touchThreshold = 10
// let locked = true

export const TabsContainer = (items: ITab[]) => {

    const base = Base()
    const inner = Div()
    inner.append(...items)
    base.append(inner)

    let ox = 0
    let dx = 0
    // inner.el.addEventListener('touchstart', handleTouchStart)
    // inner.el.addEventListener('touchmove', handleTouchMove, { passive: false })
    // inner.el.addEventListener('touchend', handleTouchEnd)
    // inner.el.addEventListener('touchcancel', handleTouchEnd)
    inner.el.addEventListener('transitionend', handleTransitionEnd)

    // function handleTouchStart(e: TouchEvent) {
    //     inner.style(EASE(0))
    //     ox = e.touches[0].clientX
    // }

    // function handleTouchMove(e: TouchEvent) {
    //     if (locked) return
    //     if (force.isScrolling) return
    //     let tx = e.touches[0].clientX - ox + dx
    //     if (Math.abs(tx) < touchThreshold) return
    //     force.isTouching = true
    //     inner.style(X(tx))
    // }

    // function handleTouchEnd(e: TouchEvent) {
    //     if (!force.isTouching) return force.isScrolling = false
    //     force.isTouching = false
    //     force.isScrolling = false

    //     const tx = e.changedTouches[0].clientX - ox
    //     const screenWidth = window.innerWidth
    //     const switchThreshold = 4
    
    //     if (Math.abs(tx) < touchThreshold) {
    //         inner.style(EASE(0.3))
    //         inner.style(X(dx))
    //         return
    //     }
    
    //     let newIndex = Math.round((dx + tx * switchThreshold) / screenWidth)
    //     newIndex = Math.max(0, Math.min(newIndex, items.length - 1))
    
    //     dx = newIndex * screenWidth

    //     inner.style(EASE(0.3))
    //     inner.style(X(dx))
    // }

    function handleTransitionEnd(e: TransitionEvent) {
        if (e.propertyName !== 'transform') return
        const index = Math.abs(dx / window.innerWidth)
        const id = items[index].id
        base.emit('tab-selected', id)
        force.isTouching = false
    }

    function select(id: string) {
        inner.style(EASE(0.3, 'transform', 'cubic-bezier(0.22, 0.73, 0.46, 1)'))
        const index = items.findIndex(item => item.id === id)
        dx = index * window.innerWidth
        inner.style(X(dx))
    }

    function reset() {
        inner.style(EASE(0))
        dx = 0
        inner.style(X(dx))
    }

    // function unlock() {
    //     locked = false
    // }   
    // This just handles the UI, not the data
    // Setting data should be done by referincing the tab items

    inner.cssClass({
        position: 'absolute',
        top: '0',
        right: '0',
        left: -100 * (items.length - 1) + '%',
        bottom: '0',
        display: 'flex',
        flexDirection: 'row',
    })

    base.cssClass({
        position: 'absolute',
        overflow: 'hidden',
        top: '0',
        left: '0',
        right: '0',
        width: '100%',
        height: '100%',
    })

    return Object.assign(base, {
        select,
        reset,
        // unlock
    })
}