import { Base } from "../../../base/components/base"
import { Div } from "../../../base/components/native/div"
import helpers from "../../../helpers"
import { ActionBack } from "../../shared/action-back"
import { ActionButton } from "../../shared/action-button"
import { DInput } from "../../shared/d-input"
import { WizardSlide } from "../../shared/slide/slide"

export const EditPhone = (label: string, value: string) => {

    const base = Base()
    const cell = DInput(label, value, { size: 36, pattern: '[0-9]*', inputmode: 'numeric' })
    const next = ActionButton('تایید')
    const prev = ActionBack('لغو')
    const keys = Div()
    keys.append(next, prev)
    base.append(cell, keys)

    cell.setValue(value)
    next.on('click', () => {
        const cellValue = cell.getValue();
        const formattedValue = helpers.locale.replacePersianDigits(cellValue);
        base.emit('update')
    })
    prev.on('click', () => base.emit('cancel'))
    cell.on('input', ({ value }: any) => value.match(/^۰۹[۰-۹]{9}$/) ? next.enable() : next.disable())

    keys.cssClass({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '40px',
        gap: '16px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    return Object.assign(base, {
        getValue() {
            const formattedValue = helpers.locale.replacePersianDigits(cell.getValue());
            return formattedValue.trim()
        }
    })
}