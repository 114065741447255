import { IBaseComponent } from "../../../../base/components/base"
import images from "../../../../configs/images"
import { Days } from "../../../shared/days/days"
import { Tab } from "../../../shared/tabs/tab"
import { Months } from "../../../shared/months/months"
import { emitter } from "../../../../services/emitter"
import * as jalaaliJs from "../../../../jalaali-js"
import state from "../../../../base/services/state"

export type TSwipe = {
    direction: -1 | 1
    frame: IBaseComponent<'div'>
    index: number
} // Todo: handle on swipe event

export const CalendarTab = () => {

    const base = Tab()

    const months = Months()
    base.append(months)

    const days = Days()
    base.append(days)

    function refresh(date: Date = state.get('current-date') || new Date()) {
        const { jm, jy } = jalaaliJs.toJalaali(date)
        months.goto(jm, jy)
        days.goto(date, { slow: true })
    }

    let moved = false
    emitter.on('tab-selected', id => {
        if (moved) return // todo temp
        if (id === 'calendar') {
            moved = true
            days.enter({ slow: true })
        }
    })

    return Object.assign(base, {
        onFocus() {
            console.log('Clients Tab entered')
        },
        onBlur() {
            console.log('Clients Tab exited')
        },
        refresh,
        label: 'تقویم',
        icon: images.ICONS.CALENDAR,
        id: 'calendar'
    })
}