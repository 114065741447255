import { ghostify } from "../../../base/components/advanced/ghost/ghost"
import { Div } from "../../../base/components/native/div"
import { CENTER, EASE, WH } from "../../../base/helpers/style"
import helpers from "../../../helpers"
import * as jalaaliJs from "../../../jalaali-js"
import services from "../../../services"
import { emitter } from "../../../services/emitter"
import { Cell } from "./cell"

export const DayCell = ({ jm, jy, jd }: { jm: number, jy: number, jd: number }) => {
    const day = helpers.locale.replaceLatinDigits(jd + '')

    const base = Cell('')

    const ev = Div()
    ev.cssClass({
        ...WH(40),
        ...EASE(.16),
        position: 'absolute',
        borderRadius: '50%',
        backgroundColor: '#ea4c89',
        opacity: '0',
        pointerEvents: 'none',
    })
    base.append(ev)

    const title = Div(day)
    title.cssClass({
        fontWeight: 'bold',
        position: 'relative',
        fontSize: '20px',
        zIndex: '9',
    })
    base.append(title)

    const gDate = jalaaliJs.toGregorian(jy, jm, jd)
    const date = new Date(gDate.gy, gDate.gm - 1, gDate.gd)
    const wd = date.getDay()
    if ([5, 4].includes(wd)) {
        title.style({ opacity: '.5' })
    }
    const today = new Date()
    if (today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear()) {
        title.prepend(Div('امروز').cssClass(CENTER).style({
            fontSize: '12px', marginBottom: '-7px'
        }))
        // title.style({ color: '#ea4c89' })
    }
    services.appointments.byDate(date).then(appointments => {
        const aps = appointments.filter(ap => !ap.isDeleted)
        ev.style({ opacity: .1 * aps.length + '' })
    })

    base.el.onclick = () => {
        emitter.emit('day-clicked', { jm, jy, jd })
    }
    ghostify(base, { bg: '#eee' })
    return base
}