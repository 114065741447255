import { Div } from "../../../../base/components/native/div"
import state from "../../../../base/services/state"
import helpers from "../../../../helpers"
import { ActionBack } from "../../../shared/action-back"
import { ActionButton } from "../../../shared/action-button"
import { DInput } from "../../../shared/d-input"
import { WizardSlide } from "../../../shared/slide/slide"

export const DescSlide = () => {

    const base = WizardSlide()
    const desc = DInput('شرح کار', 'توضیح (اختیاری)', { size: 20, type: 'textarea' })
    const next = ActionButton(helpers.strings.NEXT_STEP)
    const prev = ActionBack(helpers.strings.PREV_STEP)
    const keys = Div()
    keys.append(prev, next)
    base.append(desc, keys)

    next.on('click', () => {
        state.merge('new-income', { description: desc.getValue() })
        base.emit('done')
    })
    prev.on('click', () => base.emit('prev'))
    desc.on('input', (value: any) => value.length > 3 ? next.enable() : next.disable())
    base.on('enter', () => { setTimeout(() => desc.focus(), 450) })
    base.on('reset', () => desc.clear())

    keys.cssClass({
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        marginTop: '40px',
        gap: '16px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    return base
}