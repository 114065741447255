import { Div } from "../../../../../../base/components/native/div"
import { ABSOLUTE } from "../../../../../../base/helpers/style"
import { ITransactionYear } from "../../../../../../interfaces/transactions"
import { Price } from "../../../../../shared/price"
import { TransactionMonth } from "./transaction-month"

export const TransactionYear = (y: ITransactionYear) => {

    // return Div(year)

    const base = Div()
    base.cssClass({
        lineHeight: '32px',
        paddingLeft: '60px',
    })



    // Row
    const row = Div()
    row.cssClass({
        display: 'grid',
        gridTemplateColumns: '98px 1fr',
        gap: '14px',
        borderBottom: '1px solid gray',
        backgroundColor: '#fff',
        position: 'sticky',
        top: '43px',
        zIndex: '3',
        padding: '14px 0 0',
    })
    base.append(row)

    // Year
    const title = Div('سال ' + y.year)
    title.cssClass({
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'left',
    })
    row.append(title)

    // Total
    const value = Price(y.total)
    value.cssClass({
        fontSize: '20px',
    })
    row.append(value)

    // Months
    y.byMonth.forEach(m => {
        base.append(TransactionMonth(m))
    })

    return base

}