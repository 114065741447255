import { Frame } from "../slider/frame"
import { Window } from "../slider/window"
import { Day } from "./day"
import { ABSOLUTE, HIDE, SHOW, Y } from "../../../base/helpers/style"
import { emitter } from "../../../services/emitter"
import * as jalaaliJs from "../../../lib/jalaali-js"
import { Arrow } from "../arrow/arrow"
import { Div } from "../../../base/components/native/div"
import state from "../../../base/services/state"
import { TSwipe } from "../../home/calendar-tab"

export const Days = () => {

    const base = Div()
    base.cssClass({
        transition: 'transform 0.24s cubic-bezier(0.22, 0.73, 0.46, 1)',
        height: '100%',
        position: 'absolute',
        top: '60px',
        left: '0',
        right: '0',
        bottom: '0',
        boxShadow: '0 0 20px rgba(0,0,0,.1)',
        // borderTopRightRadius: '20px',
        // borderTopLeftRadius: '20px',
        backgroundColor: 'white',
        ...Y(window.innerHeight - 90)
    })

    const container = Window()
    container.cssClass({
        ...ABSOLUTE,
        ...Y(60),
        transition: 'all 0.16s cubic-bezier(0.22, 0.73, 0.46, 1)',
        ...HIDE,
    })
    base.append(container)

    const arrow = Arrow({ direction: 'down' })
    base.append(arrow)

    arrow.el.onclick = () => {
        base.style(Y(window.innerHeight - 90))
        container.style({
            ...Y(60),
            ...HIDE
        })
    }

    emitter.on('month-clicked', () => { // todo: use base emitter
        base.style(Y(window.innerHeight - 90))
        container.style({
            ...Y(60),
            ...HIDE
        })
    })

    emitter.on('day-clicked', ({ jd, jm, jy }) => { // todo: use base emitter
        const { gd, gm, gy } = jalaaliJs.toGregorian(jy, jm, jd) // todo: move to services.calendar
        const date = new Date(gy, gm - 1, gd)
        goto(date)
        base.style(Y(0))
        container.style({
            ...Y(0),
            ...SHOW
        }, 200)
    })

    let _thedate: Date
    container.on('swipe-end', ({ direction, frame, index }: TSwipe) => {
        const { jm, jd } = jalaaliJs.toJalaali(_thedate)
        const newDate = new Date(_thedate)
        newDate.setDate(newDate.getDate() + index + direction)
        const { jm: newJm } = jalaaliJs.toJalaali(newDate)
        frame.empty()
        const day = Day(newDate)
        frame.append(day)
        if (jm !== newJm) emitter.emit('move-month-by-one', direction)

        const currentDate = new Date(_thedate)
        currentDate.setDate(currentDate.getDate() + index)
        state.set('current-date', currentDate)
    })


    function goto(date: Date, options = {}) {

        _thedate = date
        state.set('current-date', date)

        const before = new Date(date)
        before.setDate(date.getDate() - 1)
        const framePrev = Frame()
        const dayPrev = Day(before)
        framePrev.append(dayPrev)

        const frameCurrent = Frame()
        const dayCurrent = Day(date, 'ok')

        frameCurrent.append(dayCurrent)

        const after = new Date(date)
        after.setDate(date.getDate() + 1)
        const frameNext = Frame()
        const dayNext = Day(after)
        frameNext.append(dayNext)

        container.init([framePrev, frameCurrent, frameNext])
    }

    function enter(options: any = {}) {
        if (options.slow) {
            base.style({ transition: 'transform 0.64s cubic-bezier(0.22, 0.73, 0.46, 1)' })
            base.style({ transition: 'transform 0.24s cubic-bezier(0.22, 0.73, 0.46, 1)' }, 100)
        }
        base.style(Y(0), 500)
        container.style({
            ...Y(0),
            ...SHOW,
            transition: 'all 0.16s cubic-bezier(0.22, 0.73, 0.46, 1)',
        }, 780)

    }

    return Object.assign(base, {
        goto,
        enter
    })
}