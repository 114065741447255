import { View } from './components/view'
import { HomePage } from './components/pages/home/home-page'
import { MenuPage } from './components/pages/menu/menu'
import router from './base/lib/router'
import { Div } from './base/components/native/div'
import configs from './configs'
import { LoginPage } from './components/pages/login/login-page'
import { MenuIcon } from './components/shared/menu-icon'
import { BackIcon } from './components/shared/back-icon'
import { ClientPage } from './components/pages/clients/client-page'
import { EditPage } from './components/pages/edit/edit-page'
import { NewNotificationPage } from './components/pages/notifications/new-notification-page'
import { RepeatAppointmentPage } from './components/pages/repeat/repeat-appointment-page'
import { AddIncomePage } from './components/pages/incomes/add-income-page'
import { AddAppointmentPage } from './components/pages/appointments/add-appointment-page'
import { AddClientPage } from './components/pages/add-client/add-client-page'
import { AppointmentPage } from './components/pages/clients/parts/client-tabs/appointments/appoitnment-page'

const view = View()
const app = Div()
const menuIcon = MenuIcon()
const backIcon = BackIcon()
app.append(menuIcon, backIcon, view)

app.cssClass({
    margin: '0 auto',
    transition: 'all .16s',
    overflow: 'hidden',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    paddingTop: 'env(safe-area-inset-top)',
    [`@media (max-width: ${configs.sizes.MOBILE}px)`]: {
        margin: '0 auto',
    }
})

const routes = {
    '/': HomePage,
    '/menu': MenuPage,
    '/login': LoginPage,
    '/clients/add': AddClientPage,
    '/clients/:clientId': ClientPage,
    '/clients/:clientId/edit/:field': EditPage,
    '/clients/:clientId/appointments/add': AddAppointmentPage,
    '/clients/:clientId/appointments/:appointmentId': AppointmentPage,
    '/clients/:clientId/appointments/:appointmentId/edit/:field': EditPage,
    '/appointments/:appointmentId/notifications/add': NewNotificationPage,
    '/appointments/:appointmentId/repeat': RepeatAppointmentPage,
    '/appointments/:appointmentId/repeat/edit/:field': EditPage,
    '/edit': EditPage,
    '/clients/:clientId/incomes/add': AddIncomePage,
    // '/clients/:id/edit': AddClientPage,
    // '/clients/:id/transactions': TransactionsPage,
    // '/clients/:id/transactions/add': AddTransactionPage,
    // '/clients/:id/transactions/:transactionId': TransactionPage,
    // '/clients/:id/transactions/:transactionId/edit': AddTransactionPage,

}

router.init({ routes, view })



export default app