import { Div } from '../../../../base/components/native/div'
import { ABSOLUTE } from '../../../../base/helpers/style'
import { Day } from './day'

export const DayPicker = (height?: number) => {

    if (!height) height = Math.floor((window.innerHeight - 240) / 60) * 60

    const base = Div()
    const wrapper = Div()
    const topMask = Div()
    const bottomMask = Div()
    const today = new Date()
    const formattedToday = Day(today)
    const todayTitle = Div('امـروز')
    formattedToday.append(todayTitle)
    wrapper.append(formattedToday)
    base.append(topMask, bottomMask, wrapper)

    const days = 60
    for (let i = 1; i < days; i++) {
        // from current date to 10 days backword
        const datePrev = new Date()
        datePrev.setDate(datePrev.getDate() - i)
        const formattedPrev = Day(datePrev)
        wrapper.prepend(formattedPrev)
        const dateNext = new Date()

        if (i === 0) continue
        dateNext.setDate(dateNext.getDate() + i)
        const formattedNext = Day(dateNext)
        wrapper.append(formattedNext)
    }

    base.on('mounted', () => {
        wrapper.el.scrollTop = (days - 2) * 60
    })

    let maskHeight = Math.round((height - 60) / 2 / 60) * 60
    const maskStyle = {
        position: 'absolute',
        height: maskHeight + 'px',
        background: 'linear-gradient(0deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 1) 100%)',
        top: '0',
        right: '0',
        left: '5px',
        zIndex: '99',
        pointerEvents: 'none',
    }

    todayTitle.cssClass({ zIndex: '-1', backgroundColor: '#00c4ff22', padding: '3px 30px 2px', fontSize: '12px', ...ABSOLUTE, left: '5px' })
    topMask.cssClass({ ...maskStyle, borderBottom: '1px solid #00000055' })
    bottomMask.cssClass({ ...maskStyle, top: (maskHeight + 60) + 'px', borderBottom: '1px solid #00000055', transform: 'rotate(180deg)' })
    base.cssClass({
        position: 'relative',
    })
    wrapper.cssClass({
        scrollSnapType: 'y mandatory',
        height: height + 'px',
        overflowY: 'scroll',
    })

    let lastIndex = 0;

    wrapper.el.addEventListener('scroll', () => {
        // Get the current scroll position
        const currentScrollPosition = wrapper.el.scrollTop;

        // Calculate the index of the item in view by dividing the scroll position by the item height
        const currentIndex = Math.round(currentScrollPosition / 60);

        // If the index has changed, vibrate
        if (currentIndex !== lastIndex) {
            // Check if Vibration API is supported
            if (navigator.vibrate) {
                navigator.vibrate(10); // Vibrate for 50ms
            }

            // Update the last known index
            lastIndex = currentIndex;
        }
    });

    return Object.assign(base, {
        getValue() {
            const scrolledTop = days - (height / 60 - 3) - Math.round(wrapper.el.scrollTop / 60)
            const date = new Date()
            date.setDate(date.getDate() - scrolledTop)
            return date
        },
        setValue(date: Date) {
            const diff = Math.floor((today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24))
            setTimeout(() => {
                wrapper.el.scrollTop = (days - diff) * 60 - height / 2
            }, 100);
        }
    })
}