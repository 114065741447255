import { Base } from "../../../../../../base/components/base"
import { Div } from "../../../../../../base/components/native/div"
import router, { IRouteParams } from "../../../../../../base/lib/router"
import { waitFor } from "../../../../../../base/utils/wait"
import helpers from "../../../../../../helpers"
import { TAppointment } from "../../../../../../interfaces/appointments"
import { TClient } from "../../../../../../interfaces/client"
import services from "../../../../../../services"
import appointments from "../../../../../../services/appointments"
import { ActionButton } from "../../../../../shared/action-button"
import { Body } from "../../../../../shared/body"
import { DangerButton } from "../../../../../shared/danger-button"
import { PageHeader } from "../../../../../shared/page-header"
import { Prop } from "../../../../../shared/prop"


export const AppointmentPage = () => {

    let _state: any = {}
    const base = Base()
    const header = PageHeader('شرح کار انجام شده')
    const body = Body()
    const date = Prop('تاریخ', 'date', 'date')
    const time = Prop('ساعت', 'time', 'time')
    const fees = Prop('دستمزد', 'price', 'fees')
    const desc = Prop('شرح کار', 'text', 'description')
    const user = Prop('مشتری', 'text', 'clientId')
    const omit = DangerButton('حذف این نوبت')

    body.append(date, time, fees, desc, user, omit)
    base.append(header, body, /* email, notes, address, birthday */)
    omit.on('click', async () => {
        await appointments.delete(_state._id)
        router.back()
    })
    omit.cssClass({
        margin: '60px',
        width: 'fit-content',
    })
    // date.on('edit', () => {
    //     date.edit()
    // })

    async function setData(appointment: TAppointment) {
        date.setData(helpers.date.format(appointment.date))
        time.setData(appointment.time)
        fees.setData(appointment.fees)
        desc.setData(appointment.description)
        const client = await services.clients.byId(appointment.clientId) as TClient
        user.setData(client?.name)
        // email.el.innerText = client.email
        // notes.el.innerText = client.notes
        // address.el.innerText = client.address
        // birthday.el.innerText = client.birthday
    }

    base.cssClass(helpers.styles.PAGE_BASE)
    body.cssClass({
        marginTop: '45px',
        padding: '0 0 16px 16px',
    })
    desc.cssClass({
        fontSize: '16px'
    })

    function exit({ to }: IRouteParams) {
        if (to?.includes('add') || to?.includes('edit')) {
            return base.style(helpers.styles.PAGE_EXIT_UP)
        }
        base.style(helpers.styles.PAGE_EXIT)
    }

    async function enter({ params }: IRouteParams) {
        try {
            const _id = params.appointmentId
            _state._id = _id
            const appointment = await services.appointments.byId<any>(_id)
            setData(appointment)
        } catch (error) {
            base.append(Div('خطا در دریافت اطلاعات' + error.message))
        } finally {
            await waitFor(helpers.styles.PAGE_TRANSITION_DURATION)
            base.style(helpers.styles.PAGE_ENTER)
        }
    }

    return Object.assign(base, { enter, exit })
}