import { shortUUID } from "../base/utils/id-generator"
import { waitFor } from "../base/utils/wait"
import db from "./db"
import flags from "./flags"

export default {
    async get(page: number) {
        return await db.all('clients', page)
    },
    async byId<T>(id: string): Promise<T> {
        return await db.byId('clients', id) as T
    },
    async add(data: any) {
        await new Promise(r => setTimeout(r, 500))
        const client = {
            ...data,
            id: shortUUID(),
            at: new Date(),
            synced: false
        }
        const saved = await db.save('clients', client)

        const count = await this.count()
        flags.set('CLIENTS_NOT_EMPTY', true)
        if (await count === 1) flags.set('FIRST_CLIENT_JUST_ADDED', true)
        if (count === 4) flags.set('A_FEW_CLIENTS_ADDED', true)
        if (count === 10) flags.set('MORE_CLIENTS_ADDED', true)

        return saved
    },
    async update(id: string, data: any) {
        return db.update('clients', id, data)
    },

    async count() {
        return db.count('clients')
    },

    async delete(id: string) {
        return db.delete('clients', id)
    }
}