import { ghostify } from "../../../base/components/advanced/ghost/ghost"
import { Div } from "../../../base/components/native/div"
import { Img } from "../../../base/components/native/img"
import router from "../../../base/lib/router"
import images from "../../../configs/images"
import { TAppointment } from "../../../interfaces/appointments"
import { ReminderStatus } from "../../../interfaces/reminder"
import services from "../../../services"
import { Loading } from "../loading"
import defined from "./statuses/defined"
import initial from "./statuses/initial"
import sent from "./statuses/sent"
import waiting from "./statuses/waiting"

export const AppointmentReminder = (item: TAppointment) => {

    const base = Div()
    base.cssClass({
        position: 'relative',
        border: '1px solid #414141',
        width: 'fit-content',
        borderRadius: '25px',
        padding: '4px 10px',
        fontSize: '13px',
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        '&:active': {
            transform: 'scale(0.99)'
        }
    })

    const bellIcon = Img(images.ICONS.BELL, { width: 18 })
    base.append(bellIcon)

    const title = Div()
    base.append(title)

    const loading = Loading(undefined, 40)
    base.append(loading)

    ghostify(base, { bg: '#fff' })

    base.el.onclick = (e) => {
        e.stopPropagation()
        router.goto(`/clients/${item.clientId}/setup-reminder/${item.id}`)
    }
    services.clients.byId(item.clientId).then((user: any) => {
        services.reminder.getSubscriptionStatus(user.contact.cell.replace('0', '98')).then(({ data, status }) => {
            console.log({ data, status });

            let statuss = (status !== 403 && status !== 401) ? ReminderStatus.DEFINED : ReminderStatus.INITIAL
            console.log({ statuss });

            loading.remove()
            switch (statuss) {
                case ReminderStatus.WAITING_FOR_USER_TO_JOIN_THE_BOT:
                    bellIcon.remove()
                    title.text(waiting.text)
                    base.prepend(waiting.icon())
                    base.style(sent.style)
                    break
                case ReminderStatus.SENT:
                    bellIcon.remove()
                    title.text(sent.text)
                    base.prepend(sent.icon())
                    base.style(sent.style)
                    break
                case ReminderStatus.DEFINED:
                    bellIcon.remove()
                    title.text(defined.text)
                    base.prepend(defined.icon())
                    base.style(defined.style)
                    break
                default:
                    title.text(initial.text)
                    break
            }
        })
        console.log({ user });
    })

    return base
}