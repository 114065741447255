import { Div } from '../../../../base/components/native/div'
import state from '../../../../base/services/state'
import helpers from '../../../../helpers'
import locale from '../../../../helpers/locale'
import utils from '../../../../utils'
import { ActionBack } from '../../../shared/action-back'
import { ActionButton } from '../../../shared/action-button'
import { DInput } from '../../../shared/d-input'
import { WizardSlide } from '../../../shared/slide/slide'

export const FeesSlide = () => {

    const base = WizardSlide()
    const cost = DInput('دستمزد دریافتی (تومان)', '250,000', { size: 32, pattern: '[0-9]*', inputmode: 'numeric' })
    const next = ActionButton('ذخیره')
    const prev = ActionBack(helpers.strings.PREV_STEP)
    const keys = Div()
    keys.append(prev, next)
    base.append(cost, keys)

    next.on('click', () => { // todo: move logic to the input component
        let fees = locale.replacePersianDigits(cost.getValue())
        if (isNaN(parseInt(fees))) fees = '0'
        state.merge('new-appointment', { fees: parseInt(fees) })
        base.emit('done')
    })
    prev.on('click', () => base.emit('prev'))
    base.on('enter', () => setTimeout(() => cost.focus(), 450))
    base.on('reset', () => cost.clear())

    next.cssClass({
        backgroundColor: '#4ceacd'
    })
    keys.cssClass({
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-between',
        marginTop: '40px',
        gap: '16px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    return base
}