import { Frame } from "../../slider/frame"
import { Window } from "../../slider/window"
import { TSwipe } from "../../pages/home/parts/calendar-tab"
import { Day } from "./day"

export const Days = () => {
    const base = Window()
    let _thedate: Date

    base.on('swipe', ({ direction, frame, index }: TSwipe) => {
        const newDate = new Date(_thedate)
        newDate.setDate(newDate.getDate() + index + direction)
        frame.empty()
        const day = Day(newDate)
        frame.append(day)
    })

    base.cssClass({
        height: '100%',
    })
    function goto(date: Date) {
        _thedate = date
        const theday = new Date()
        const before = new Date(theday)
        before.setDate(theday.getDate() - 1)
        const after = new Date(theday)
        after.setDate(theday.getDate() + 1)

        const framePrev = Frame()
        const dayPrev = Day(before)
        framePrev.append(dayPrev)

        const frameCurrent = Frame()
        const dayCurrent = Day(date)
        frameCurrent.append(dayCurrent)

        const frameNext = Frame()
        const dayNext = Day(after)
        frameNext.append(dayNext)

        base.init([framePrev, frameCurrent, frameNext])
    }

    return Object.assign(base, {
        goto,
    })

}